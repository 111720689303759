.RightSidebar {
  position: fixed;
  top: 100px;
  right: 30px;
}

.RightSidebar-RankingAndToken {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #cde4eb;
  border-radius: 15px;
  width: 250px;
  height: 260px;
}

.RightSidebar-Ranking {
  position: relative;
  display: flex;
  flex-direction: column;
}

.RightSidebar-GRanking {
  position: relative;
  margin-top: 10px;
}

.RightSidebar-GRanking img {
  width: 70px;
}

.RightSidebar-TotalRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  left: -12px;
  padding-left: 12px;
  padding-right: 12px;
}

.RightSidebar-TotalRanking h1 {
  position: relative;
  top: -4px;
  width: 50px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.RightSidebar-TotalRanking p {
  position: relative;
  top: -10px;
  width: 150px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.RightSidebar-DocRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  height: 30px;
}

.RightSidebar-DocRanking h1 {
  width: 50px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.RightSidebar-DocRanking p {
  position: relative;
  top: -5px;
  width: 150px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.RightSidebar-CommentRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  height: 30px;
}

.RightSidebar-CommentRanking h1 {
  width: 50px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.RightSidebar-CommentRanking p {
  position: relative;
  top: -5px;
  width: 150px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.RightSidebar-Token {
  position: relative;
  display: flex;
  flex-direction: column;
}

.RightSidebar-GToken {
  position: relative;
  margin-top: 10px;
}

.RightSidebar-GToken img {
  width: 70px;
}

.RightSidebar-BigComingSoon {
  position: relative;
}

.RightSidebar-BigComingSoon img {
  width: 70px;
  height: 70px;
}
