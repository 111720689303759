.Notification {
  background-color: #fafafa;
}

.Notification-Body p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Notification-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Notification-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Notification-TrashAll button {
  position: relative;
  margin-top: 9px;
  margin-left: 200px;
  width: 5vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #f14b4b;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.Notification-LoadingBar {
  position: absolute;
  left: 30vw;
  width: 40vw;
}

.Notification-Messages {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -5px;
  margin-bottom: 40px;
  margin-left: calc(50vw - 350px);
  width: 700px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Notification-Comment {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 10px;
  width: 700px;
  min-height: 50px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Notification-Comment button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
  cursor: pointer;
  width: 700px;
  background-color: transparent;
  border: 0;
}

.Notification-CommentTrash img {
  margin-top: 12px;
  margin-right: 48px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.Notification-Reply {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 10px;
  width: 700px;
  min-height: 50px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Notification-Reply button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
  cursor: pointer;
  width: 700px;
  background-color: transparent;
  border: 0;
}

.Notification-ReplyTrash img {
  margin-top: 12px;
  margin-right: 48px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.Notification-Avatar img {
  position: relative;
  top: 5px;
  left: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Notification-Time {
  position: relative;
  right: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  width: 150px;
}
