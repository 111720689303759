.MobileReport {
  background-color: #fafafa;
}

.MobileReport-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 10vw;
  width: 80vw;
}

.MobileReport-Title h1 {
  position: relative;
  width: 50vw;
  left: 17vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileReport-DislikeChecked {
  position: absolute;
  left: 15vw;
  top: 20vh;
  width: 75vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.MobileReport-DislikeChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileReport-DislikeChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileReport-HarmfulChecked {
  position: absolute;
  left: 15vw;
  top: 25vh;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.MobileReport-HarmfulChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileReport-HarmfulChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileReport-AdChecked {
  position: absolute;
  left: 15vw;
  top: 30vh;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.MobileReport-AdChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileReport-AdChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileReport-PornChecked {
  position: absolute;
  left: 15vw;
  top: 35vh;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.MobileReport-PornChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileReport-PornChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileReport-EtcChecked {
  position: absolute;
  left: 15vw;
  top: 40vh;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.MobileReport-EtcChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileReport-EtcChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileReport-EtcInputBody textarea {
  position: absolute;
  left: 12vw;
  top: 45vh;
  resize: none;
  width: 70vw;
  height: 60px;
  margin-top: 18px;
  margin-left: 20px;
  font-family: Pretendard, serif;
  font-size: 15px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  word-wrap: break-word;
  outline: none;
}

.MobileReport-Continue button {
  position: absolute;
  top: 63vh;
  left: 40vw;
  width: 20vw;
  height: 5vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}
