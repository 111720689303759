.MobileNotification {
  background-color: #fafafa;
  overflow: hidden;
}

.MobileNotification-Body p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileNotification-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 25vw;
  width: 80vw;
}

.MobileNotification-Title h1 {
  position: relative;
  width: 30vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileNotification-TrashAll button {
  position: relative;
  margin-top: 9px;
  margin-left: 20vw;
  width: 30vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #f14b4b;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.MobileNotification-LoadingBar {
  position: absolute;
  left: 2.5vw;
  width: 95vw;
}

.MobileNotification-Messages {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -5px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MobileNotification-Comment {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 2.5vw;
  width: 95vw;
  min-height: 50px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotification-Comment button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  width: 95vw;
  background-color: transparent;
  border: 0;
  color: black;
}

.MobileNotification-CommentTrash img {
  margin-top: 14px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.MobileNotification-Reply {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 2.5vw;
  width: 95vw;
  min-height: 50px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotification-Reply button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  width: 95vw;
  background-color: transparent;
  border: 0;
  color: black;
}

.MobileNotification-ReplyTrash img {
  margin-top: 14px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.MobileNotification-Avatar img {
  position: relative;
  top: 5px;
  left: 5px;
  margin-right: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileNotification-Time {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 14px;
  width: 150px;
}
