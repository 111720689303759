.Event {
  background-color: #fafafa;
}

.Event-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Event-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Event-Write button {
  position: relative;
  left: 200px;
  top: 5px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Event-LoadingBar {
  position: absolute;
  left: 30vw;
  width: 40vw;
}

.Event-Documents {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Event-Document {
  position: relative;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.Event-DocumentHeader {
  position: relative;
  display: flex;
}

.Event-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  top: 10px;
  left: calc(22.5vw - 10px);
  z-index: 1;
  cursor: pointer;
}

.Event-Content {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 18px;
  font-weight: 550;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  word-wrap: break-word;
  margin-left: 24px;
  margin-top: -10px;
  padding-right: 26px;
}

.Event-Content img {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  border-radius: 12px;
  word-wrap: break-word;
}

.Event-Content iframe {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  height: 400px;
  border-radius: 12px;
  word-wrap: break-word;
}
