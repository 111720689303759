.App {
  text-align: center;
  font-family: Pretendard, serif;
  background-color: #ffffff;
}

@font-face {
  font-family: Pretendard;
  src: url("./asset/font/PretendardVariable.ttf");
}
