.MobileProfileView {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  overflow: hidden;
}

.MobileProfileView-Title {
  margin-top: 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 10vw;
  width: 80vw;
}

.MobileProfileView-Title img {
  object-fit: cover;
  width: 40px;
}

.MobileProfileView-Title h1 {
  position: absolute;
  margin-top: 10px;
  width: 80vw;
  left: 1vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileProfileView-Main {
  position: relative;
  width: 95vw;
  margin-top: 5px;
  left: 2.5vw;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  margin-bottom: 140px;
}

.MobileProfileView-ProfileMain {
  position: relative;
  border-bottom: 1px solid #d4d4d8;
}

.MobileProfileView-ProfileHeader {
  position: relative;
  display: flex;
}

.MobileProfileView-Avatar img {
  position: relative;
  margin-top: 16px;
  margin-left: 16px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 48px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileProfileView-Avatar-CameraPlus {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #f8f8fa;
  border: 1px solid #d4d4d8;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.MobileProfileView-Names {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-Name p {
  position: relative;
  margin-left: 8px;
  margin-top: -16px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
}

.MobileProfileView-Nickname p {
  position: relative;
  margin-top: 26px;
  margin-left: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.MobileProfileView-Nickname input {
  position: relative;
  margin-top: 16px;
  margin-left: -2px;
  margin-bottom: 16px;
  padding-left: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: left;
  background-color: #f8f8fa;
  border: 1px solid #fffefc;
  border-radius: 15px;
  width: 180px;
}

.MobileProfileView-Status {
  display: flex;
  flex-direction: row;
}

.MobileProfileView-NumFollowers {
  margin-left: -44px;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
}

.MobileProfileView-NumFollowers h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.MobileProfileView-NumFollowers p {
  margin-left: 8px;
  margin-top: 9px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.MobileProfileView-NumFollowings {
  margin-left: 8px;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
}

.MobileProfileView-NumFollowings h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.MobileProfileView-NumFollowings p {
  margin-left: 8px;
  margin-top: 9px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.MobileProfileView-NumPosts {
  margin-left: 8px;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
}

.MobileProfileView-NumPosts h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.MobileProfileView-NumPosts p {
  margin-left: 8px;
  margin-top: 9px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.MobileProfileView-NumBookmarks {
  margin-left: 8px;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
}

.MobileProfileView-NumBookmarks h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.MobileProfileView-NumBookmarks p {
  margin-left: 8px;
  margin-top: 9px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.MobileProfileView-Bio p {
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
  text-align: justify;
  width: 84vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  word-wrap: break-word;
}

.MobileProfileView-Bio textarea {
  position: relative;
  margin-top: 10px;
  padding-top: 8px;
  padding-left: 8px;
  text-align: left;
  width: 84vw;
  height: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: #f8f8fa;
  border: 1px solid #fffefc;
  border-radius: 15px;
  word-wrap: break-word;
}

.MobileProfileView-RankingAndToken {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #cde4eb;
  border-radius: 15px;
  left: 4vw;
  width: 87vw;
  height: 150px;
}

.MobileProfileView-VerticalLine img {
  width: 1px;
  margin-top: 20px;
  margin-left: 1.5vw;
  height: 120px;
}

.MobileProfileView-Ranking {
  position: relative;
  display: flex;
  flex-direction: column;
}

.MobileProfileView-GRanking {
  position: relative;
  left: 1.5vw;
  margin-top: 10px;
}

.MobileProfileView-GRanking img {
  width: 50px;
}

.MobileProfileView-TotalRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 1.5vw;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  padding-left: 4px;
  padding-right: 4px;
}

.MobileProfileView-TotalRanking h1 {
  position: relative;
  top: -2px;
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileProfileView-TotalRanking p {
  position: relative;
  top: -6px;
  width: 32vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.MobileProfileView-DocRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(1.5vw + 4px);
  height: 30px;
}

.MobileProfileView-DocRanking h1 {
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileProfileView-DocRanking p {
  position: relative;
  top: -5px;
  width: 32vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.MobileProfileView-CommentRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(1.5vw + 4px);
  height: 30px;
}

.MobileProfileView-CommentRanking h1 {
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileProfileView-CommentRanking p {
  position: relative;
  top: -5px;
  width: 32vw;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.MobileProfileView-Token {
  position: relative;
  display: flex;
  flex-direction: column;
}

.MobileProfileView-GToken {
  position: relative;
  left: 10vw;
  margin-top: 10px;
  width: 20vw;
}

.MobileProfileView-GToken img {
  position: relative;
  width: 50px;
}

.MobileProfileView-BigComingSoon {
  position: relative;
  margin-top: 10px;
  left: 10.5vw;
}

.MobileProfileView-BigComingSoon img {
  width: 50px;
  height: 50px;
}

.MobileProfileView-Edit button {
  position: relative;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  width: calc(45vw - 60px);
  border-radius: 15px;
}

.MobileProfileView-FollowOrUnfollow button {
  position: relative;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  width: calc(45vw - 60px);
  border-radius: 15px;
}

.MobileProfileView-Tab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2px;
  margin-top: 24px;
}

.MobileProfileView-TabFollowers-Focus {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-TabFollowers-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabFollowers-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.MobileProfileView-TabFollowers button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabFollowings-Focus {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-TabFollowings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabFollowings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.MobileProfileView-TabFollowings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabPosts-Focus {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-TabPosts-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabPosts-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.MobileProfileView-TabPosts button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabBookmarks-Focus {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-TabBookmarks-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-TabBookmarks-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.MobileProfileView-TabBookmarks button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.MobileProfileView-Details {
  position: relative;
  display: flex;
  flex-direction: column;
}

.MobileProfileView-LoadingBar {
  position: absolute;
  width: 90vw;
}

.MobileProfileView-Followers-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileProfileView-Followings-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileProfileView-Posts-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileProfileView-Bookmarks-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileProfileView-Follower {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.MobileProfileView-Following {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.MobileProfileView-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin-bottom: 10px;
}

.MobileProfileView-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 24px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileProfileView-UserNames {
  display: flex;
  flex-direction: column;
}

.MobileProfileView-UserName button {
  position: absolute;
  left: 40px;
  margin-top: 18px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  color: black;
}

.MobileProfileView-UserNickname button {
  position: absolute;
  left: 40px;
  margin-top: 34px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.MobileProfileView-UserFollowOrUnfollow button {
  position: absolute;
  margin-top: 25px;
  right: 20px;
  width: 70px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}

.MobileProfileView-Document {
  margin-bottom: 5px;
  border-top: 1px solid #d4d4d8;
}

.MobileProfileView-DocumentHeader {
  position: relative;
  display: flex;
}

.MobileProfileView-DocumentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileProfileView-DocumentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
  color: black;
}

.MobileProfileView-Time {
  position: relative;
  margin-top: 10px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: gray;
}

.MobileProfileView-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 40px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileProfileView-Bookmark img {
  width: 18px;
  height: 18px;
}

.MobileProfileView-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileProfileView-Misc img {
  width: 24px;
  height: 24px;
}

.MobileProfileView-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 50px;
  width: 110px;
  height: 70px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileProfileView-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 40px;
  width: 110px;
  height: 40px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileProfileView-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.MobileProfileView-Block img {
  position: relative;
  display: flex;
  top: -2px;
  width: 20px;
  height: 20px;
}

.MobileProfileView-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 5px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileProfileView-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 30px;
}

.MobileProfileView-Report img {
  position: relative;
  display: flex;
  top: -8px;
  width: 20px;
  height: 20px;
}

.MobileProfileView-Report p {
  position: relative;
  display: flex;
  top: -20px;
  left: 5px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileProfileView-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.MobileProfileView-Delete img {
  position: relative;
  display: flex;
  top: -3px;
  width: 20px;
  height: 20px;
}

.MobileProfileView-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 5px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileProfileView-ThumbnailContent {
  position: relative;
  background-color: transparent;
  margin-left: 50px;
  margin-top: -20px;
  align-self: start;
  width: calc(85vw + 15px);
}

.MobileProfileView-ThumbnailContent button {
  position: relative;
  width: 103%;
  font-family: Pretendard, serif;
  font-size: 15px;
  align-self: start;
  text-align: justify;
  padding-right: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  color: black;
}

.MobileProfileView-ThumbnailContent img {
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.MobileProfileView-ThumbnailContent iframe {
  width: 100%;
  height: 30vh;
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.MobileProfileView-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 20px;
}

.MobileProfileView-Hashtags li {
  list-style: none;
}

.MobileProfileView-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
  width: 120px;
  height: 20px;
}

.MobileProfileView-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  z-index: 1;
}

.MobileProfileView-Hashtag button {
  position: relative;
  top: -20px;
  margin-left: 10px;
  background-color: transparent;
  border: none;
  border-radius: 24px;
  width: 150px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: black;
}

.MobileProfileView-ReadMore button {
  position: relative;
  left: calc(47.5vw - 50px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileProfileView-ReadMore img {
  width: 90px;
  height: 20px;
}

.MobileProfileView-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  left: calc(47.5vw - 140px);
  align-self: start;
}

.MobileProfileView-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileProfileView-NumUps img {
  width: 20px;
  height: 9px;
}

.MobileProfileView-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileProfileView-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileProfileView-NumUps-Focus img {
  width: 22px;
  height: 10px;
}

.MobileProfileView-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.MobileProfileView-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileProfileView-NumDowns img {
  width: 42px;
  height: 9px;
}

.MobileProfileView-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileProfileView-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileProfileView-NumDowns-Focus img {
  width: 46px;
  height: 10px;
}

.MobileProfileView-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.MobileProfileView-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileProfileView-NumComments img {
  width: 16px;
  height: 16px;
}

.MobileProfileView-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileProfileView-NumTokens button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileProfileView-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 12px;
  font-weight: 800;
  color: #767676;
}

.MobileProfileView-ComingSoon {
  position: relative;
}

.MobileProfileView-ComingSoon img {
  width: 40px;
  height: 40px;
}
