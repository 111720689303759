.Sidebar {
  position: fixed;
  top: 190px;
  left: 0;
}

.Sidebar-Base img {
  object-fit: contain;
  width: 150px;
}

.Sidebar-Home img {
  position: fixed;
  top: 260px;
  left: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.Sidebar-Home img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Sidebar-Search img {
  position: fixed;
  top: 330px;
  left: 30px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.Sidebar-Search img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Sidebar-Plus img {
  position: fixed;
  top: 420px;
  left: 30px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.Sidebar-Plus img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Sidebar-Bell img {
  position: fixed;
  top: 520px;
  left: 30px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.Sidebar-Bell img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Sidebar-Profile img {
  position: fixed;
  top: 590px;
  left: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.Sidebar-Profile img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}
