.MobileSearchQuery {
    background-color: #ffffff;
    overflow: hidden;
}

.MobileSearchQuery-Title {
    margin-top: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    left: 30vw;
    width: 40vw;
}

.MobileSearchQuery-Title h1 {
    position: relative;
    width: 20vw;
    left: 10vw;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 500;
}

.MobileSearchQuery-LoadingBar {
    position: absolute;
    left: 5vw;
    width: 90vw;
    top: 82px;
}

.MobileSearchQuery-SearchQueryHashtag {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 24px;
    position: relative;
}

.MobileSearchQuery-SearchQueryHashtag ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

.MobileSearchQuery-SearchQueryHashtag-tag {
    position: absolute;
    font-family: Pretendard, serif;
    font-size: 16px;
    padding: 10px;
    left: calc(30vw - 10px);
    width: 40vw;
}

.MobileSearchQuery-Documents {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 40px;
    margin-bottom: 130px;
    margin-left: 2.5vw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.MobileSearchQuery-Document {
    position: relative;
    width: 95vw;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
    margin-top: 5px;
}

.MobileSearchQuery-DocumentHeader {
    position: relative;
    display: flex;
}

.MobileSearchQuery-Avatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileSearchQuery-Nickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.MobileSearchQuery-Time {
    position: relative;
    margin-top: 10px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: gray;
}

.MobileSearchQuery-Bookmark button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    right: 40px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileSearchQuery-Bookmark img {
    width: 18px;
    height: 18px;
}

.MobileSearchQuery-Misc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    right: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileSearchQuery-Misc img {
    width: 24px;
    height: 24px;
}

.MobileSearchQuery-MiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 70px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileSearchQuery-MiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 40px;
    width: 110px;
    height: 40px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileSearchQuery-Block {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.MobileSearchQuery-Block img {
    position: relative;
    display: flex;
    top: -2px;
    width: 20px;
    height: 20px;
}

.MobileSearchQuery-Block p {
    position: relative;
    display: flex;
    top: -15px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileSearchQuery-Report {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 30px;
}

.MobileSearchQuery-Report img {
    position: relative;
    display: flex;
    top: -8px;
    width: 20px;
    height: 20px;
}

.MobileSearchQuery-Report p {
    position: relative;
    display: flex;
    top: -20px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileSearchQuery-Delete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.MobileSearchQuery-Delete img {
    position: relative;
    display: flex;
    top: -3px;
    width: 20px;
    height: 20px;
}

.MobileSearchQuery-Delete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileSearchQuery-ThumbnailContent {
    position: relative;
    background-color: transparent;
    margin-left: 50px;
    margin-top: -20px;
    align-self: start;
    width: calc(85vw + 15px);
}

.MobileSearchQuery-ThumbnailContent button {
    position: relative;
    width: 103%;
    font-family: Pretendard, serif;
    font-size: 15px;
    align-self: start;
    text-align: left;
    padding-right: 50px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    color: black;
}

.MobileSearchQuery-ThumbnailContent img {
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.MobileSearchQuery-ThumbnailContent iframe {
    width: 100%;
    height: 30vh;
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.MobileSearchQuery-Hashtags ul {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 20px;
}

.MobileSearchQuery-Hashtags li {
    list-style: none;
}

.MobileSearchQuery-Hashtag {
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    padding: 2px;
    width: 120px;
    height: 20px;
}

.MobileSearchQuery-Hashtag img {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;
    z-index: 1;
}

.MobileSearchQuery-Hashtag button {
    position: relative;
    top: -20px;
    margin-left: 10px;
    background-color: transparent;
    border: none;
    border-radius: 24px;
    width: 150px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: left;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: black;
}

.MobileSearchQuery-ReadMore button {
    position: relative;
    left: calc(47.5vw - 50px);
    display: flex;
    margin: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileSearchQuery-ReadMore img {
    width: 90px;
    height: 20px;
}

.MobileSearchQuery-Summary {
    display: flex;
    flex-direction: row;
    position: relative;
    left: calc(47.5vw - 140px);
    align-self: start;
}

.MobileSearchQuery-NumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileSearchQuery-NumUps img {
    width: 20px;
    height: 9px;
}

.MobileSearchQuery-NumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileSearchQuery-NumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileSearchQuery-NumUps-Focus img {
    width: 22px;
    height: 10px;
}

.MobileSearchQuery-NumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.MobileSearchQuery-NumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileSearchQuery-NumDowns img {
    width: 42px;
    height: 9px;
}

.MobileSearchQuery-NumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileSearchQuery-NumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileSearchQuery-NumDowns-Focus img {
    width: 46px;
    height: 10px;
}

.MobileSearchQuery-NumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.MobileSearchQuery-NumComments button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileSearchQuery-NumComments img {
    width: 16px;
    height: 16px;
}

.MobileSearchQuery-NumComments p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileSearchQuery-NumTokens button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileSearchQuery-NumTokens p {
    font-family: Pretendard, serif;
    font-size: 12px;
    font-weight: 800;
    color: #767676;
}

.MobileSearchQuery-ComingSoon {
    position: relative;
}

.MobileSearchQuery-ComingSoon img {
    width: 40px;
    height: 40px;
}
