.NotificationView {
  background-color: #fafafa;
}

.NotificationView-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.NotificationView-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.NotificationView-Message {
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.NotificationView-Message-Void {
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.NotificationView-Message-Void p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-DocumentHeader {
  position: relative;
  display: flex;
}

.NotificationView-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  left: 0px;
  top: -8px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.NotificationView-Nickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.NotificationView-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.NotificationView-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 60px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-Bookmark img {
  width: 18px;
  height: 18px;
}

.NotificationView-Report button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-Report img {
  width: 24px;
  height: 24px;
}

.NotificationView-Content {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 24px;
  margin-top: -10px;
  padding-right: 26px;
}

.NotificationView-Content img {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  border-radius: 12px;
  word-wrap: break-word;
}

.NotificationView-Content iframe {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  height: 400px;
  border-radius: 12px;
  word-wrap: break-word;
}

.NotificationView-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 34px;
}

.NotificationView-Hashtags li {
  list-style: none;
}

.NotificationView-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.NotificationView-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.NotificationView-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.NotificationView-ReadMore button {
  position: relative;
  left: calc(22.5vw - 60px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReadMore img {
  width: 90px;
  height: 20px;
}

.NotificationView-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
}

.NotificationView-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumUps img {
  width: 22px;
  height: 10px;
}

.NotificationView-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumUps-Focus img {
  width: 24px;
  height: 11px;
}

.NotificationView-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.NotificationView-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumDowns img {
  width: 44px;
  height: 10px;
}

.NotificationView-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.NotificationView-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.NotificationView-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumComments img {
  width: 18px;
  height: 18px;
}

.NotificationView-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-NumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.NotificationView-ComingSoon {
  position: relative;
}

.NotificationView-ComingSoon img {
  width: 50px;
  height: 50px;
}

.NotificationView-Comment {
  display: flex;
  flex-direction: column;
  width: 45vw;
  border-top: 1px solid #d4d4d8;
}

.NotificationView-CommentHeader {
  position: relative;
  display: flex;
  width: 99%;
}

.NotificationView-CommentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.NotificationView-CommentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.NotificationView-CommentTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.NotificationView-CommentReport button {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 15px;
  right: 20px;
}

.NotificationView-CommentReport img {
  width: 24px;
  height: 24px;
}

.NotificationView-CommentBody {
  position: relative;
  width: 85%;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 75px;
  margin-top: -30px;
}

.NotificationView-CommentSummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
  margin-top: -15px;
}

.NotificationView-CommentNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumUps img {
  width: 22px;
  height: 10px;
}

.NotificationView-CommentNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-CommentNumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumUps-Focus img {
  width: 24px;
  height: 11px;
}

.NotificationView-CommentNumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.NotificationView-CommentNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumDowns img {
  width: 44px;
  height: 10px;
}

.NotificationView-CommentNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-CommentNumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.NotificationView-CommentNumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.NotificationView-CommentNumReplies button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumReplies img {
  width: 18px;
  height: 18px;
}

.NotificationView-CommentNumReplies p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-CommentNumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentNumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.NotificationView-ReplyInput {
  position: relative;
  width: 45vw;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d4d4d8;
  background-color: #f8f8fa;
  border-bottom: 1px solid #fffefc;
  border-radius: 25px;
}

.NotificationView-ReplyImage img {
  position: relative;
  margin-left: 40px;
  margin-top: 20px;
  height: 24px;
}

.NotificationView-ReplyInputAvatar img {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.NotificationView-ReplyInputBody {
  display: flex;
  flex-direction: column;
}

.NotificationView-ReplyInputBody textarea {
  position: relative;
  resize: none;
  width: 30vw;
  height: 60px;
  margin-top: 18px;
  margin-left: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #f8f8fa;
  border-radius: 10px;
  word-wrap: break-word;
  outline: none;
  background-color: #f8f8fa;
}

.NotificationView-ReplyInputBody button {
  position: absolute;
  right: 20px;
  margin-top: 20px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #fffefc;
  border: 1px solid #fffefc;
  border-radius: 10px;
  width: 60px;
  height: 40px;
}

.NotificationView-LoadingBar {
  position: relative;
  width: 45vw;
  z-index: 1;
}

.NotificationView-Reply {
  background-color: #f8f8fa;
  border-top: 1px solid #d4d4d8;
}

.NotificationView-ReplyHeader {
  position: relative;
  display: flex;
}

.NotificationView-ReplyAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.NotificationView-ReplyNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.NotificationView-ReplyTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.NotificationView-ReplyReport button {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 15px;
  right: 20px;
}

.NotificationView-ReplyReport img {
  width: 24px;
  height: 24px;
}

.NotificationView-ReplyBody {
  position: relative;
  width: 76%;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 130px;
  margin-top: -30px;
}

.NotificationView-ReplySummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 124px;
  align-self: start;
  margin-top: -15px;
}

.NotificationView-ReplyNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReplyNumUps img {
  width: 22px;
  height: 10px;
}

.NotificationView-ReplyNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-ReplyNumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReplyNumUps-Focus img {
  width: 24px;
  height: 11px;
}

.NotificationView-ReplyNumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.NotificationView-ReplyNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReplyNumDowns img {
  width: 44px;
  height: 10px;
}

.NotificationView-ReplyNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.NotificationView-ReplyNumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReplyNumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.NotificationView-ReplyNumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.NotificationView-ReadMoreReplies {
  background-color: #f8f8fa;
  border-bottom: 1px solid #fffefc;
  border-radius: 25px;
}

.NotificationView-ReadMoreReplies button {
  width: 10vw;
  height: 4vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
  margin: 10px auto;
}

.NotificationView-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-Misc img {
  width: 24px;
  height: 24px;
}

.NotificationView-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -15px;
}

.NotificationView-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 20px;
}

.NotificationView-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -15px;
}

.NotificationView-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-CommentMisc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-CommentMisc img {
  width: 24px;
  height: 24px;
}

.NotificationView-CommentMiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-CommentMiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-CommentBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -8px;
}

.NotificationView-CommentBlock img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-CommentBlock p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-CommentReport {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 25px;
}

.NotificationView-CommentReport img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-CommentReport p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-CommentDelete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -8px;
}

.NotificationView-CommentDelete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-CommentDelete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-ReplyMisc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.NotificationView-ReplyMisc img {
  width: 24px;
  height: 24px;
}

.NotificationView-ReplyMiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-ReplyMiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 50px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.NotificationView-ReplyBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -15px;
}

.NotificationView-ReplyBlock img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-ReplyBlock p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-ReplyReport {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 20px;
}

.NotificationView-ReplyReport img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-ReplyReport p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.NotificationView-ReplyDelete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -15px;
}

.NotificationView-ReplyDelete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.NotificationView-ReplyDelete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}
