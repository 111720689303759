.Ranking {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.Ranking-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 47vw;
  width: 40vw;
}

.Ranking-Title img {
  width: 80px;
}

.Ranking-LoadingBar {
  position: absolute;
  top: -50px;
  width: 45vw;
}

.Ranking-TabAndMain {
  position: relative;
  left: 27.5vw;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 15px;
}

.Ranking-Tab {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 32px;
  margin-top: 24px;
  left: 7.5vw;
  width: 30vw;
}

.Ranking-Frequency {
  position: relative;
  display: flex;
  flex-direction: row;
}

.Ranking-Frequency img {
  position: relative;
  left: 15px;
  top: 80px;
  width: 3px;
  height: 3px;
}

.Ranking-Frequency p {
  position: relative;
  display: flex;
  left: 25px;
  top: 55px;
  width: 200px;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  color: #767676;
  z-index: 1;
}

.Ranking-MyRanking button {
  position: relative;
  display: flex;
  left: calc(44vw - 150px);
  top: 20px;
  width: 150px;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  color: #767676;
  z-index: 1;
}

.Ranking-TabTotalRankings-Focus {
  display: flex;
  flex-direction: column;
}

.Ranking-TabTotalRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-TabTotalRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.Ranking-TabTotalRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-TabDocRankings-Focus {
  display: flex;
  flex-direction: column;
}

.Ranking-TabDocRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-TabDocRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.Ranking-TabDocRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-TabCommentRankings-Focus {
  display: flex;
  flex-direction: column;
}

.Ranking-TabCommentRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-TabCommentRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.Ranking-TabCommentRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.Ranking-HorizontalLine img {
  position: relative;
  top: -34px;
  width: 45vw;
  height: 1px;
}

.Ranking-Main {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.Ranking-TotalRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Ranking-DocRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Ranking-CommentRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Ranking-TotalRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.Ranking-DocRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.Ranking-CommentRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.Ranking-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 45vw;
  margin-bottom: 10px;
}

.Ranking-RankingNumber h1 {
  margin-top: 32px;
  margin-left: 15px;
  font-family: Pretendard, serif;
  font-size: 15px;
  font-weight: 600;
  color: #505050;
}

.Ranking-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Ranking-UserNames {
  display: flex;
  flex-direction: column;
}

.Ranking-UserName button {
  position: absolute;
  left: 100px;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Ranking-UserNickname button {
  position: absolute;
  left: 100px;
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Ranking-UserFollowOrUnfollow button {
  position: absolute;
  margin-top: 24px;
  right: 20px;
  width: 100px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}

.Ranking-Detail {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 16vw;
  margin-top: 18px;
}

.Ranking-TotalDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  left: -12px;
  padding-left: 12px;
  padding-right: 12px;
}

.Ranking-TotalDetail h1 {
  position: relative;
  top: -4px;
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Ranking-TotalDetail p {
  position: relative;
  top: -10px;
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.Ranking-DocDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 30px;
}

.Ranking-DocDetail h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Ranking-DocDetail p {
  position: relative;
  top: -5px;
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.Ranking-CommentDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 30px;
}

.Ranking-CommentDetail h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Ranking-CommentDetail p {
  position: relative;
  top: -5px;
  width: 7vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}
