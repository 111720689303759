.Event {
    background-color: #fafafa;
}

.MobileEvent-Title {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    left: 10vw;
    width: 80vw;
}

.MobileEvent-Title h1 {
    position: relative;
    width: 80vw;
    left: 1vw;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 500;
}

.MobileEvent-Write button {
    position: relative;
    left: 200px;
    top: 5px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileEvent-LoadingBar {
    position: absolute;
    left: 2.5vw;
    width: 95vw;
}

.MobileEvent-Documents {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 5px;
    margin-bottom: 40px;
    margin-left: 2.5vw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.MobileEvent-Document {
    position: relative;
    width: 95vw;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
    margin-top: 5px;
    margin-bottom: 130px;
}

.MobileEvent-DocumentHeader {
    position: relative;
    display: flex;
}

.MobileEvent-Delete img {
    position: relative;
    display: flex;
    width: 20px;
    top: 10px;
    left: calc(22.5vw - 10px);
    z-index: 1;
    cursor: pointer;
}

.MobileEvent-Content {
    position: relative;
    font-family: Pretendard, serif;
    font-size: 15px;
    font-weight: 550;
    text-align: left;
    background-color: transparent;
    border: 1px solid transparent;
    word-wrap: break-word;
    margin-left: 0px;
    margin-top: -10px;
    margin-right: 4px;
}

.MobileEvent-Content img {
    position: relative;
    margin-top: 18px;
    left: 2px;
    width: 100%;
    border-radius: 12px;
    word-wrap: break-word;
}

.MobileEvent-Content iframe {
    position: relative;
    margin-top: 18px;
    left: 2px;
    width: 100%;
    height: 30vh;
    border-radius: 12px;
    word-wrap: break-word;
}
