.MobileBlock-Block {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.MobileBlock-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 10vw;
  width: 80vw;
}

.MobileBlock-Title img {
  object-fit: cover;
  width: 40px;
}

.MobileBlock-Title h1 {
  position: absolute;
  width: 80vw;
  left: 1vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileBlock-Main {
  position: relative;
  width: 95vw;
  margin-top: 5px;
  left: 2.5vw;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
}

.MobileBlock-Blockings {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
}

.MobileBlock-Blockings-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileBlock-Blocking {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.MobileBlock-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 95vw;
  margin-bottom: 2px;
}

.MobileBlock-UserAvatar img {
  position: relative;
  margin-top: 8px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileBlock-UserNames {
  display: flex;
  flex-direction: column;
}

.MobileBlock-UserName button {
  position: absolute;
  left: 50px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.MobileBlock-UserNickname button {
  position: absolute;
  left: 50px;
  margin-top: 24px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.MobileBlock-UserUnblock button {
  position: absolute;
  margin-top: 16px;
  right: 20px;
  width: 80px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}
