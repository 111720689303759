.MobileRanking {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  overflow: hidden;
}

.MobileRanking-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 43vw;
  width: 50vw;
}

.MobileRanking-Title img {
  width: 60px;
}

.MobileRanking-LoadingBar {
  position: absolute;
  top: -58px;
  left: 2.5vw;
  width: 90vw;
}

.MobileRanking-TabAndMain {
  position: relative;
  left: 2.5vw;
  width: 95vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 15px;
}

.MobileRanking-Tab {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 32px;
  margin-top: 12px;
  left: 2.5vw;
  width: 95vw;
}

.MobileRanking-Frequency {
  position: relative;
  display: flex;
  flex-direction: row;
  top: -4px;
}

.MobileRanking-Frequency img {
  position: relative;
  left: 15px;
  top: 76px;
  width: 3px;
  height: 3px;
}

.MobileRanking-Frequency p {
  position: relative;
  display: flex;
  left: 25px;
  top: 55px;
  width: 200px;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  color: #767676;
  z-index: 1;
}

.MobileRanking-MyRanking button {
  position: relative;
  display: flex;
  left: calc(95vw - 150px);
  top: 20px;
  width: 150px;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  color: #767676;
  z-index: 1;
}

.MobileRanking-TabTotalRankings-Focus {
  display: flex;
  flex-direction: column;
}

.MobileRanking-TabTotalRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-TabTotalRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.MobileRanking-TabTotalRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-TabDocRankings-Focus {
  display: flex;
  flex-direction: column;
}

.MobileRanking-TabDocRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-TabDocRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.MobileRanking-TabDocRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-TabCommentRankings-Focus {
  display: flex;
  flex-direction: column;
}

.MobileRanking-TabCommentRankings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-TabCommentRankings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 80px;
  height: 3px;
}

.MobileRanking-TabCommentRankings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
  z-index: 1;
}

.MobileRanking-HorizontalLine img {
  position: relative;
  top: -34px;
  width: 95vw;
  height: 1px;
}

.MobileRanking-Main {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 140px;
}

.MobileRanking-TotalRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileRanking-DocRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileRanking-CommentRankings-Void p {
  margin-top: 50px;
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileRanking-TotalRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.MobileRanking-DocRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.MobileRanking-CommentRanking {
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.MobileRanking-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 95vw;
  margin-bottom: 10px;
}

.MobileRanking-RankingNumber h1 {
  margin-top: 32px;
  margin-left: 15px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 600;
  color: #505050;
  width: 35px;
}

.MobileRanking-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 0px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileRanking-UserNames {
  display: flex;
  flex-direction: column;
}

.MobileRanking-UserName button {
  position: absolute;
  left: 84px;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  color: black;
}

.MobileRanking-UserNickname button {
  position: absolute;
  left: 84px;
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.MobileRanking-UserFollowOrUnfollow button {
  position: absolute;
  margin-top: 70px;
  left: 20px;
  width: 120px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}

.MobileRanking-Detail {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 32vw;
  margin-top: 18px;
}

.MobileRanking-TotalDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  left: 0px;
  padding-left: 8px;
  padding-right: 8px;
}

.MobileRanking-TotalDetail h1 {
  position: relative;
  top: -1px;
  width: 35px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileRanking-TotalDetail p {
  position: relative;
  top: -5px;
  width: 80px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.MobileRanking-DocDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 30px;
  left: 8px;
}

.MobileRanking-DocDetail h1 {
  width: 35px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileRanking-DocDetail p {
  position: relative;
  top: -4px;
  width: 80px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.MobileRanking-CommentDetail {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 30px;
  left: 8px;
}

.MobileRanking-CommentDetail h1 {
  width: 35px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.MobileRanking-CommentDetail p {
  position: relative;
  top: -4px;
  width: 80px;
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}
