.MobileSignUp-Title h1 {
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 18px;
}

.MobileSignUp-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 15vh;
  width: 20vw;
  gap: 30px;
}

.MobileSignUp-Background img {
  width: 70vw;
}

.MobileSignUp-Required {
  position: absolute;
  z-index: 1;
  left: 72vw;
  top: 110px;
  margin-left: 2px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.MobileSignUp-Required h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileSignUp-Required p {
  position: relative;
  top: -3px;
  margin-left: 8px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #229ec5;
}

.MobileSignUp-Inputs {
  position: absolute;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 10vw;
  width: 80vw;
  height: 730px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileSignUp-EmailInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-EmailInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-EmailInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-EmailInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-EmailInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-EmailInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-PasswordInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-PasswordInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-PasswordInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-PasswordInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-PasswordInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-PasswordInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-PasswordCheckInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-PasswordCheckInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-PasswordCheckInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-PasswordCheckInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-PasswordCheckInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-PasswordCheckInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-NameInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-NameInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-NameInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-NameInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-NameInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-NameInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-NicknameInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-NicknameInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-NicknameInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-NicknameInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-NicknameInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-NicknameInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-GenderInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-GenderInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-GenderInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-GenderInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-GenderInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-GenderInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-DoBInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.MobileSignUp-DoBInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.MobileSignUp-DoBInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileSignUp-DoBInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.MobileSignUp-DoBInputForm {
  margin-left: 10px;
  width: calc(80vw - 30px);
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.MobileSignUp-DoBInputForm input {
  position: relative;
  margin-left: 10px;
  width: calc(80vw - 30px);
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileSignUp-ContinueActive button {
  position: absolute;
  margin-top: 690px;
  margin-left: -21.5vw;
  width: 43vw;
  height: 5vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}

.MobileSignUp-ContinueInactive button {
  position: absolute;
  margin-top: 690px;
  margin-left: -21.5vw;
  width: 43vw;
  height: 5vh;
  background-color: #d4d4d8;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}
