.MobileEula-Title {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20vh;
  left: 40vw;
  width: 20vw;
}

.MobileEula-Title img {
  width: min(40vw, 200px);
}

.MobileEula-Title h1 {
  width: 70vw;
  font-family: Pretendard, serif;
  font-size: 24px;
}

.MobileEula-Title p {
  margin-top: -10px;
  width: 70vw;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileEula-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20vh;
  width: 20vw;
  gap: 30px;
}

.MobileEula-Background img {
  width: 70vw;
}

.MobileEula-MobileEulaChecked {
  position: absolute;
  left: 5vw;
  top: max(45vh, 150px);
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MobileEula-MobileEulaChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileEula-MobileEulaChecked button {
  position: absolute;
  font-family: Pretendard, serif;
  font-size: 14px;
  width: 70vw;
  left: 20vw;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: black;
  text-align: left;
}

.MobileEula-AgeChecked {
  position: absolute;
  left: 5vw;
  top: max(51.5vh, 150px);
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MobileEula-AgeChecked input {
  left: 0;
  zoom: 1.5;
}

.MobileEula-AgeChecked button {
  position: absolute;
  font-family: Pretendard, serif;
  font-size: 14px;
  width: 80vw;
  left: 20vw;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: black;
  text-align: left;
}

.MobileEula-View button {
  position: absolute;
  top: 0.5vh;
  margin-left: 55vw;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  width: min(15vw, 80px);
  text-align: center;
}

.MobileEula-Continue button {
  position: absolute;
  top: 63vh;
  left: 25vw;
  width: 50vw;
  height: 30px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}
