.Block-Block {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.Block-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Block-Title img {
  object-fit: cover;
  width: 48px;
}

.Block-Title h1 {
  position: absolute;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Block-Main {
  position: relative;
  width: 45vw;
  margin-top: 5px;
  left: 27.5vw;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
}

.Block-Blockings {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Block-Blockings-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Block-Blocking {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.Block-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 45vw;
  margin-bottom: 10px;
}

.Block-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Block-UserNames {
  display: flex;
  flex-direction: column;
}

.Block-UserName button {
  position: absolute;
  left: 72px;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Block-UserNickname button {
  position: absolute;
  left: 72px;
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Block-UserUnblock button {
  position: absolute;
  margin-top: 30px;
  right: 20px;
  width: 100px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}
