.Login-Title {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 150px;
  left: 40vw;
  width: 20vw;
  gap: 30px;
}

.Login-Title img {
  width: 20vw;
}

.Login-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 15vh;
  left: 20vw;
  width: 20vw;
  gap: 30px;
}

.Login-Background img {
  width: 38vw;
}

.Login-Email {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 270px;
  left: calc(50vw - 200px);
  width: 400px;
  height: 40px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  background-color: #fffefc;
}

.Login-Email img {
  position: absolute;
  top: 9px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.Login-Email input {
  position: absolute;
  top: 7px;
  left: 30px;
  width: 23vw;
  height: 25px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.Login-Password {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 320px;
  left: calc(50vw - 200px);
  width: 400px;
  height: 40px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  background-color: #fffefc;
}

.Login-Password img {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.Login-Password input {
  position: absolute;
  top: 7px;
  left: 30px;
  width: 23vw;
  height: 25px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.Login-SignIn button {
  position: absolute;
  top: 380px;
  left: calc(50vw - 200px);
  width: 400px;
  height: 40px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}

.Login-Help {
  top: 440px;
  position: absolute;
  display: flex;
  flex-direction: row;
  left: calc(40vw - 1px);
  justify-content: space-around;
}

.Login-Help img {
  width: 2px;
  height: 20px;
}

.Login-EmailVerification button {
  width: 10vw;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  color: #767676;
}

.Login-PasswordReset button {
  width: 10vw;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  color: #767676;
}

.Login-SignUp {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 430px;
  gap: 40px;
  justify-content: center;
}

.Login-SignUp p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Login-SignUp button {
  background-color: transparent;
  color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.Login-EasySignInTitle {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 450px;
  gap: 10px;
  justify-content: center;
}

.Login-EasySignInTitle img {
  width: 9vw;
  height: 1px;
}

.Login-EasySignInTitle p {
  margin-top: -8px;
}

.Login-EasySignIn {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 470px;
  gap: 30px;
  justify-content: center;
}

.Login-Naver img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Login-Kakao img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Login-Google img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Login-Facebook img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Login-Apple img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
