.AdminHeader {
  border-bottom: 3px solid #f1f1f5;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.AdminHeader-Logo img {
  aspect-ratio: 4;
  object-fit: contain;
  width: 180px;
  max-width: 100%;
  margin-top: -5px;
  cursor: pointer;
}

.AdminHeader-Navigation {
  position: absolute;
  display: flex;
  color: #767676;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: center;
  width: 50vw;
  left: calc(50vw - 200px);
  margin-top: -15px;
}

.AdminHeader-DocumentsTab button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-DocumentsTab-Focus {
  display: flex;
  flex-direction: column;
}

.AdminHeader-DocumentsTab-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-DocumentsTab-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}

.AdminHeader-CommentsTab button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-CommentsTab-Focus {
  display: flex;
  flex-direction: column;
}

.AdminHeader-CommentsTab-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-CommentsTab-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}

.AdminHeader-RepliesTab button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-RepliesTab-Focus {
  display: flex;
  flex-direction: column;
}

.AdminHeader-RepliesTab-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-RepliesTab-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}

.AdminHeader-SearchTab button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-SearchTab-Focus {
  display: flex;
  flex-direction: column;
}

.AdminHeader-SearchTab-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.AdminHeader-SearchTab-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}
