.Search {
  background-color: #fafafa;
}

.Search-Title {
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Search-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Search-LoadingBar {
  position: absolute;
  margin-top: 90px;
  left: 30vw;
  width: 40vw;
}

.Search-SearchBar {
  position: relative;
  left: 30vw;
  width: 36.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  margin-top: 5px;
  border-top: 1px solid #d4d4d8;
  border-bottom: 1px solid #d4d4d8;
}

.Search-SearchBar img {
  width: 18px;
  height: 18px;
}

.Search-SearchBar input {
  flex-grow: 1;
  height: 15px;
  border: none;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  color: #767676;
  outline: none;
}

.Search-SearchHashtags {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  margin-top: -20px;
}

.Search-SearchHashtags ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.Search-SearchHashtags-tag {
  position: absolute;
  z-index: 1;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
  padding: 10px;
  top: 3vh;
  left: calc(30vw - 10px);
  width: 40vw;
}

.Search-Documents {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Search-Document {
  position: relative;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.Search-DocumentHeader {
  position: relative;
  display: flex;
}

.Search-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Search-Nickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.Search-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.Search-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 60px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-Bookmark img {
  width: 18px;
  height: 18px;
}

.Search-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-Misc img {
  width: 24px;
  height: 24px;
}

.Search-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Search-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Search-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Search-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Search-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Search-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 30px;
}

.Search-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Search-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Search-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Search-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Search-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Search-ThumbnailContent {
  position: relative;
  background-color: transparent;
  margin-left: 70px;
  margin-top: -20px;
  align-self: start;
}

.Search-ThumbnailContent button {
  position: relative;
  width: 100%;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: start;
  text-align: start;
  padding-right: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
}

.Search-ThumbnailContent img {
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Search-ThumbnailContent iframe {
  width: 99%;
  height: 400px;
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Search-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 34px;
}

.Search-Hashtags li {
  list-style: none;
}

.Search-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.Search-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.Search-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  text-align: left;
}

.Search-ReadMore button {
  position: relative;
  left: calc(22.5vw - 60px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-ReadMore img {
  width: 90px;
  height: 20px;
}

.Search-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
}

.Search-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumUps img {
  width: 22px;
  height: 10px;
}

.Search-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Search-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumUps-Focus img {
  width: 24px;
  height: 11px;
}

.Search-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.Search-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumDowns img {
  width: 44px;
  height: 10px;
}

.Search-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Search-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.Search-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.Search-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumComments img {
  width: 18px;
  height: 18px;
}

.Search-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Search-NumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Search-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.Search-ComingSoon {
  position: relative;
}

.Search-ComingSoon img {
  width: 50px;
  height: 50px;
}
