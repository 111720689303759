.EasySignUp-Title h1 {
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 18px;
}

.EasySignUp-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 15vh;
  left: 20vw;
  width: 20vw;
  gap: 30px;
}

.EasySignUp-Background img {
  width: 38vw;
}

.EasySignUp-Required {
  position: absolute;
  z-index: 1;
  left: 68vw;
  top: 110px;
  margin-left: 2px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.EasySignUp-Required h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.EasySignUp-Required p {
  position: relative;
  top: -3px;
  margin-left: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EasySignUp-Inputs {
  position: relative;
  margin-top: 40px;
  margin-left: 27.5vw;
  width: 45vw;
  padding-bottom: 15vh;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.EasySignUp-NameInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EasySignUp-NameInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.EasySignUp-NameInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EasySignUp-NameInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EasySignUp-NameInputForm {
  margin-left: 10px;
  width: 43vw;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EasySignUp-NameInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EasySignUp-NicknameInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EasySignUp-NicknameInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.EasySignUp-NicknameInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EasySignUp-NicknameInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EasySignUp-NicknameInputForm {
  margin-left: 10px;
  width: 43vw;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EasySignUp-NicknameInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EasySignUp-GenderInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EasySignUp-GenderInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.EasySignUp-GenderInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EasySignUp-GenderInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EasySignUp-GenderInputForm {
  width: 43vw;
  margin-left: 10px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EasySignUp-GenderInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EasySignUp-DoBInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EasySignUp-DoBInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.EasySignUp-DoBInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EasySignUp-DoBInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EasySignUp-DoBInputForm {
  width: 43vw;
  margin-left: 10px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EasySignUp-DoBInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EasySignUp-ContinueActive button {
  position: relative;
  top: -7vh;
  width: 43vw;
  height: 30px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}

.EasySignUp-ContinueInactive button {
  position: relative;
  top: -7vh;
  width: 43vw;
  height: 30px;
  background-color: #d4d4d8;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}
