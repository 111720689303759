.Profile-Profile {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.Profile-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Profile-Title img {
  object-fit: cover;
  width: 48px;
}

.Profile-Title h1 {
  position: absolute;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Profile-Main {
  position: relative;
  width: 45vw;
  margin-top: 5px;
  left: 27.5vw;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  margin-bottom: 40px;
}

.Profile-ProfileMain {
  position: relative;
  border-bottom: 1px solid #d4d4d8;
}

.Profile-ProfileHeader {
  position: relative;
  display: flex;
}

.Profile-Avatar img {
  position: relative;
  margin-top: 32px;
  margin-left: 32px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 72px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Profile-Avatar-CameraPlus {
  position: absolute;
  top: 84px;
  left: 90px;
  background-color: #f8f8fa;
  border: 1px solid #d4d4d8;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.Profile-Names {
  display: flex;
  flex-direction: column;
}

.Profile-Name p {
  position: relative;
  margin-left: 48px;
  margin-top: -16px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #505050;
  text-align: left;
}

.Profile-Name input {
  position: relative;
  margin-left: -8px;
  margin-bottom: 16px;
  padding-left: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: left;
  background-color: #f8f8fa;
  border: 1px solid #fffefc;
  border-radius: 15px;
  width: 250px;
}

.Profile-Nickname p {
  position: relative;
  margin-top: 32px;
  margin-left: 48px;
  font-family: Pretendard, serif;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.Profile-Nickname input {
  position: relative;
  margin-top: 32px;
  margin-left: -8px;
  margin-bottom: 16px;
  padding-left: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: left;
  background-color: #f8f8fa;
  border: 1px solid #fffefc;
  border-radius: 15px;
  width: 250px;
}

.Profile-Status {
  display: flex;
  flex-direction: row;
}

.Profile-NumFollowers {
  margin-left: 48px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.Profile-NumFollowers h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.Profile-NumFollowers p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.Profile-NumFollowings {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.Profile-NumFollowings h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.Profile-NumFollowings p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.Profile-NumPosts {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.Profile-NumPosts h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.Profile-NumPosts p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.Profile-NumBookmarks {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.Profile-NumBookmarks h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.Profile-NumBookmarks p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.Profile-Bio p {
  position: relative;
  margin-left: 32px;
  text-align: left;
  width: 40vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  word-wrap: break-word;
}

.Profile-Bio textarea {
  position: relative;
  padding-top: 8px;
  padding-left: 8px;
  text-align: left;
  width: 40vw;
  height: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: #f8f8fa;
  border: 1px solid #fffefc;
  border-radius: 15px;
  word-wrap: break-word;
}

.Profile-RankingAndToken {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #cde4eb;
  border-radius: 15px;
  left: 1.5vw;
  width: 42vw;
  height: 160px;
}

.Profile-VerticalLine img {
  width: 1px;
  margin-top: 20px;
  margin-left: 3vw;
  height: 120px;
}

.Profile-Ranking {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Profile-GRanking {
  position: relative;
  left: 1.5vw;
  margin-top: 10px;
}

.Profile-GRanking img {
  width: 70px;
}

.Profile-TotalRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 2.5vw;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.Profile-TotalRanking h1 {
  position: relative;
  top: -4px;
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Profile-TotalRanking p {
  position: relative;
  top: -10px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.Profile-DocRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(2.5vw + 12px);
  height: 30px;
}

.Profile-DocRanking h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Profile-DocRanking p {
  position: relative;
  top: -5px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.Profile-CommentRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(2.5vw + 12px);
  height: 30px;
}

.Profile-CommentRanking h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.Profile-CommentRanking p {
  position: relative;
  top: -5px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.Profile-Token {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Profile-GToken {
  position: relative;
  left: 8vw;
  margin-top: 10px;
}

.Profile-GToken img {
  width: 70px;
}

.Profile-BigComingSoon {
  position: relative;
  margin-top: 10px;
  left: 8vw;
}

.Profile-BigComingSoon img {
  width: 70px;
  height: 70px;
}

.Profile-Edit button {
  position: relative;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  width: calc(45vw - 60px);
  border-radius: 15px;
}

.Profile-Tab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 32px;
  margin-top: 24px;
}

.Profile-TabFollowers-Focus {
  display: flex;
  flex-direction: column;
}

.Profile-TabFollowers-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabFollowers-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.Profile-TabFollowers button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabFollowings-Focus {
  display: flex;
  flex-direction: column;
}

.Profile-TabFollowings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabFollowings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.Profile-TabFollowings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabPosts-Focus {
  display: flex;
  flex-direction: column;
}

.Profile-TabPosts-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabPosts-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.Profile-TabPosts button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabBookmarks-Focus {
  display: flex;
  flex-direction: column;
}

.Profile-TabBookmarks-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-TabBookmarks-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.Profile-TabBookmarks button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.Profile-Details {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Profile-LoadingBar {
  position: absolute;
  width: 45vw;
}

.Profile-Followers-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Profile-Followings-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Profile-Posts-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Profile-Bookmarks-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.Profile-Follower {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.Profile-Following {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.Profile-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 45vw;
  margin-bottom: 10px;
}

.Profile-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Profile-UserNames {
  display: flex;
  flex-direction: column;
}

.Profile-UserName button {
  position: absolute;
  left: 72px;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Profile-UserNickname button {
  position: absolute;
  left: 72px;
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.Profile-UserFollowOrUnfollow button {
  position: absolute;
  margin-top: 30px;
  right: 20px;
  width: 100px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}

.Profile-Document {
  margin-bottom: 5px;
  border-top: 1px solid #d4d4d8;
}

.Profile-DocumentHeader {
  position: relative;
  display: flex;
}

.Profile-DocumentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Profile-DocumentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.Profile-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.Profile-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 60px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-Bookmark img {
  width: 18px;
  height: 18px;
}

.Profile-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-Misc img {
  width: 24px;
  height: 24px;
}

.Profile-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Profile-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Profile-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Profile-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Profile-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Profile-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 30px;
}

.Profile-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Profile-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Profile-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Profile-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Profile-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Profile-ThumbnailContent {
  position: relative;
  background-color: transparent;
  margin-left: 70px;
  margin-top: -20px;
  align-self: start;
}

.Profile-ThumbnailContent button {
  position: relative;
  width: 100%;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: start;
  text-align: start;
  padding-right: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
}

.Profile-ThumbnailContent img {
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Profile-ThumbnailContent iframe {
  width: 99%;
  height: 400px;
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Profile-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 34px;
}

.Profile-Hashtags li {
  list-style: none;
}

.Profile-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.Profile-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.Profile-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.Profile-ReadMore button {
  position: relative;
  left: calc(22.5vw - 60px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-ReadMore img {
  width: 90px;
  height: 20px;
}

.Profile-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
}

.Profile-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumUps img {
  width: 22px;
  height: 10px;
}

.Profile-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Profile-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumUps-Focus img {
  width: 24px;
  height: 11px;
}

.Profile-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.Profile-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumDowns img {
  width: 44px;
  height: 10px;
}

.Profile-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Profile-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.Profile-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.Profile-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumComments img {
  width: 18px;
  height: 18px;
}

.Profile-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Profile-NumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Profile-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.Profile-ComingSoon {
  position: relative;
}

.Profile-ComingSoon img {
  width: 50px;
  height: 50px;
}

.Profile-DeleteAccount button {
  width: 35vw;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  color: #767676;
}
