.ProfileView-ProfileView {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.ProfileView-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.ProfileView-Title img {
  object-fit: cover;
  width: 48px;
}

.ProfileView-Title h1 {
  position: absolute;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.ProfileView-Main {
  position: relative;
  width: 45vw;
  margin-top: 5px;
  left: 27.5vw;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  margin-bottom: 40px;
}

.ProfileView-ProfileMain {
  position: relative;
  border-bottom: 1px solid #d4d4d8;
}

.ProfileView-ProfileHeader {
  position: relative;
  display: flex;
}

.ProfileView-Avatar img {
  position: relative;
  margin-top: 32px;
  margin-left: 32px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 72px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ProfileView-Avatar-CameraPlus {
  position: absolute;
  top: 84px;
  left: 90px;
  background-color: #f8f8fa;
  border: 1px solid #d4d4d8;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.ProfileView-Names {
  display: flex;
  flex-direction: column;
}

.ProfileView-Nickname p {
  position: relative;
  margin-top: 32px;
  margin-left: 48px;
  font-family: Pretendard, serif;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.ProfileView-Name p {
  position: relative;
  margin-left: 48px;
  margin-top: -16px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #505050;
  text-align: left;
}

.ProfileView-Status {
  display: flex;
  flex-direction: row;
}

.ProfileView-NumFollowers {
  margin-left: 48px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.ProfileView-NumFollowers h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.ProfileView-NumFollowers p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.ProfileView-NumFollowings {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.ProfileView-NumFollowings h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.ProfileView-NumFollowings p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.ProfileView-NumPosts {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.ProfileView-NumPosts h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.ProfileView-NumPosts p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.ProfileView-NumBookmarks {
  margin-left: 16px;
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.ProfileView-NumBookmarks h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

.ProfileView-NumBookmarks p {
  margin-left: 12px;
  margin-top: 11px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  color: #505050;
  text-align: left;
}

.ProfileView-Bio p {
  position: relative;
  margin-left: 32px;
  text-align: left;
  width: 40vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  word-wrap: break-word;
}

.ProfileView-RankingAndToken {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #cde4eb;
  border-radius: 15px;
  left: 1.5vw;
  width: 42vw;
  height: 160px;
}

.ProfileView-VerticalLine img {
  width: 1px;
  margin-top: 20px;
  margin-left: 3vw;
  height: 120px;
}

.ProfileView-Ranking {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProfileView-GRanking {
  position: relative;
  left: 1.5vw;
  margin-top: 10px;
}

.ProfileView-GRanking img {
  width: 70px;
}

.ProfileView-TotalRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 2.5vw;
  margin-top: 2px;
  height: 30px;
  background-color: #dcf3fb;
  border-radius: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.ProfileView-TotalRanking h1 {
  position: relative;
  top: -4px;
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.ProfileView-TotalRanking p {
  position: relative;
  top: -10px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.ProfileView-DocRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(2.5vw + 12px);
  height: 30px;
}

.ProfileView-DocRanking h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.ProfileView-DocRanking p {
  position: relative;
  top: -5px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.ProfileView-CommentRanking {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: calc(2.5vw + 12px);
  height: 30px;
}

.ProfileView-CommentRanking h1 {
  width: 3vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: left;
}

.ProfileView-CommentRanking p {
  position: relative;
  top: -5px;
  width: 11vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  text-align: right;
}

.ProfileView-Token {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProfileView-GToken {
  position: relative;
  left: 8vw;
  margin-top: 10px;
}

.ProfileView-GToken img {
  width: 70px;
}

.ProfileView-BigComingSoon {
  position: relative;
  margin-top: 10px;
  left: 8vw;
}

.ProfileView-BigComingSoon img {
  width: 70px;
  height: 70px;
}

.ProfileView-FollowOrUnfollow button {
  position: relative;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d4d4d8;
  width: calc(45vw - 60px);
  border-radius: 15px;
}

.ProfileView-Tab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 32px;
  margin-top: 24px;
}

.ProfileView-TabFollowers-Focus {
  display: flex;
  flex-direction: column;
}

.ProfileView-TabFollowers-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabFollowers-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.ProfileView-TabFollowers button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabFollowings-Focus {
  display: flex;
  flex-direction: column;
}

.ProfileView-TabFollowings-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabFollowings-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.ProfileView-TabFollowings button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabPosts-Focus {
  display: flex;
  flex-direction: column;
}

.ProfileView-TabPosts-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabPosts-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.ProfileView-TabPosts button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabBookmarks-Focus {
  display: flex;
  flex-direction: column;
}

.ProfileView-TabBookmarks-Focus button {
  position: relative;
  color: #111111;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-TabBookmarks-Focus img {
  position: relative;
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 3px;
}

.ProfileView-TabBookmarks button {
  position: relative;
  color: #505050;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  width: 80px;
}

.ProfileView-Details {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProfileView-LoadingBar {
  position: absolute;
  width: 45vw;
}

.ProfileView-Followers-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.ProfileView-Followings-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.ProfileView-Posts-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.ProfileView-Bookmarks-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.ProfileView-Follower {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.ProfileView-Following {
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.ProfileView-UserHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 45vw;
  margin-bottom: 10px;
}

.ProfileView-UserAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ProfileView-UserNames {
  display: flex;
  flex-direction: column;
}

.ProfileView-UserName button {
  position: absolute;
  left: 72px;
  margin-top: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.ProfileView-UserNickname button {
  position: absolute;
  left: 72px;
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #505050;
  text-align: left;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.ProfileView-UserFollowOrUnfollow button {
  position: absolute;
  margin-top: 30px;
  right: 20px;
  width: 100px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  color: #fffefc;
}

.ProfileView-Document {
  margin-bottom: 5px;
  border-top: 1px solid #d4d4d8;
}

.ProfileView-DocumentHeader {
  position: relative;
  display: flex;
}

.ProfileView-DocumentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ProfileView-DocumentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.ProfileView-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.ProfileView-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 60px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-Bookmark img {
  width: 18px;
  height: 18px;
}

.ProfileView-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-Misc img {
  width: 24px;
  height: 24px;
}

.ProfileView-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.ProfileView-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.ProfileView-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.ProfileView-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.ProfileView-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.ProfileView-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 30px;
}

.ProfileView-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.ProfileView-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.ProfileView-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.ProfileView-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.ProfileView-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.ProfileView-ThumbnailContent {
  position: relative;
  background-color: transparent;
  margin-left: 70px;
  margin-top: -20px;
  align-self: start;
}

.ProfileView-ThumbnailContent button {
  position: relative;
  width: 100%;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: start;
  text-align: start;
  padding-right: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
}

.ProfileView-ThumbnailContent img {
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.ProfileView-ThumbnailContent iframe {
  width: 99%;
  height: 400px;
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.ProfileView-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 34px;
}

.ProfileView-Hashtags li {
  list-style: none;
}

.ProfileView-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.ProfileView-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.ProfileView-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.ProfileView-ReadMore button {
  position: relative;
  left: calc(22.5vw - 60px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-ReadMore img {
  width: 90px;
  height: 20px;
}

.ProfileView-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
}

.ProfileView-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumUps img {
  width: 22px;
  height: 10px;
}

.ProfileView-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ProfileView-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumUps-Focus img {
  width: 24px;
  height: 11px;
}

.ProfileView-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.ProfileView-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumDowns img {
  width: 44px;
  height: 10px;
}

.ProfileView-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ProfileView-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.ProfileView-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.ProfileView-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumComments img {
  width: 18px;
  height: 18px;
}

.ProfileView-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ProfileView-NumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ProfileView-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.ProfileView-ComingSoon {
  position: relative;
}

.ProfileView-ComingSoon img {
  width: 50px;
  height: 50px;
}
