.MobileTab {
  position: absolute;
  display: flex;
  flex-direction: row;
  color: #767676;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: center;
  width: 95vw;
  left: calc(50vw - 160px);
  top: 50px;
  overflow: hidden;
}

.MobileTab-Recent button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Recent button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Recent-Focus {
  display: flex;
  flex-direction: column;
}

.MobileTab-Recent-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Recent-Focus img {
  align-self: center;
  margin-top: 1px;
  width: 40px;
  height: 2px;
}

.MobileTab-Following button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Following button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Following-Focus {
  display: flex;
  flex-direction: column;
}

.MobileTab-Following-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Following-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Following-Focus img {
  align-self: center;
  margin-top: 1px;
  width: 60px;
  height: 2px;
}

.MobileTab-Random button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Random button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Random-Focus {
  display: flex;
  flex-direction: column;
}

.MobileTab-Random-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Random-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Random-Focus img {
  align-self: center;
  margin-top: 1px;
  width: 40px;
  height: 2px;
}

.MobileTab-Profile button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Profile button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Profile-Focus {
  display: flex;
  flex-direction: column;
}

.MobileTab-Profile-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 80px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 14px;
  cursor: pointer;
}

.MobileTab-Profile-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileTab-Profile-Focus img {
  align-self: center;
  margin-top: 1px;
  width: 60px;
  height: 2px;
}
