.ReportView-Main {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 45vw;
  margin-top: 5px;
  margin-left: 27.5vw;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  overflow: hidden;
}

.ReportView-DocumentHeader {
  position: relative;
  display: flex;
}

.ReportView-DocumentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  left: 0px;
  top: -8px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ReportView-DocumentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.ReportView-DocumentTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.ReportView-DocumentTrash button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-DocumentTrash img {
  width: 24px;
  height: 24px;
}

.ReportView-Content {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 24px;
  margin-top: -10px;
  padding-right: 26px;
}

.ReportView-Content img {
  position: relative;
  left: 2px;
  width: 100%;
  border-radius: 12px;
  word-wrap: break-word;
}

.ReportView-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: -18px;
}

.ReportView-Hashtags li {
  list-style: none;
}

.ReportView-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.ReportView-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.ReportView-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.ReportView-DocumentSummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 20px;
  align-self: start;
}

.ReportView-DocumentNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-DocumentNumUps img {
  width: 22px;
  height: 10px;
}

.ReportView-DocumentNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-DocumentNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-DocumentNumDowns img {
  width: 44px;
  height: 10px;
}

.ReportView-DocumentNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-DocumentNumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-DocumentNumComments img {
  width: 18px;
  height: 18px;
}

.ReportView-DocumentNumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-DocumentNumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-DocumentNumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.ReportView-ComingSoon {
  position: relative;
}

.ReportView-ComingSoon img {
  width: 50px;
  height: 50px;
}

.ReportView-Comment {
  display: flex;
  flex-direction: column;
  width: 45vw;
  border-top: 1px solid #d4d4d8;
}

.ReportView-CommentHeader {
  position: relative;
  display: flex;
  width: 99%;
}

.ReportView-CommentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ReportView-CommentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
  color: black;
}

.ReportView-CommentTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.ReportView-CommentTrash button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-CommentTrash img {
  width: 24px;
  height: 24px;
}

.ReportView-CommentBody {
  position: relative;
  width: calc(45vw - 120px);
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 78px;
  margin-top: -20px;
}

.ReportView-CommentSummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
  margin-top: -15px;
}

.ReportView-CommentNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-CommentNumUps img {
  width: 22px;
  height: 10px;
}

.ReportView-CommentNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-CommentNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-CommentNumDowns img {
  width: 44px;
  height: 10px;
}

.ReportView-CommentNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-CommentNumReplies button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-CommentNumReplies img {
  width: 18px;
  height: 18px;
}

.ReportView-CommentNumReplies p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-CommentNumTokens button {
  position: relative;
  top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-CommentNumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.ReportView-Reply {
  background-color: #f8f8fa;
  border-top: 1px solid #d4d4d8;
}

.ReportView-ReplyHeader {
  position: relative;
  display: flex;
}

.ReportView-ReplyAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.ReportView-ReplyNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.ReportView-ReplyTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.ReportView-ReplyTrash button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-ReplyTrash img {
  width: 24px;
  height: 24px;
}

.ReportView-ReplyBody {
  position: relative;
  width: calc(45vw - 180px);
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 18px;
  margin-top: -10px;
}

.ReportView-ReplySummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 12px;
  align-self: start;
  margin-top: -15px;
}

.ReportView-ReplyNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-ReplyNumUps img {
  width: 22px;
  height: 10px;
}

.ReportView-ReplyNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.ReportView-ReplyNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.ReportView-ReplyNumDowns img {
  width: 44px;
  height: 10px;
}

.ReportView-ReplyNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}
