.MobileHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  margin-top: 5px;
  height: 45px;
}

.MobileHeader-Logo img {
  position: absolute;
  aspect-ratio: 4;
  object-fit: contain;
  width: 150px;
  max-width: 100%;
  margin-top: -5px;
  cursor: pointer;
  margin-left: 5px;
}

.MobileHeader-Logo img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileHeader-Action {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.MobileHeader-Menu {
  display: flex;
  flex-direction: column;
}

.MobileHeader-Menu img {
  position: absolute;
  aspect-ratio: 1;
  object-fit: contain;
  width: 20px;
  right: 75px;
  top: 20px;
  cursor: pointer;
}

.MobileHeader-Menu img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileHeader-MenuFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: calc(100vw - 130px);
  top: 50px;
  width: 120px;
  height: 200px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileHeader-MenuFocusLogout {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: calc(90vw - 130px);
  top: 50px;
  width: 120px;
  height: 220px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileHeader-Ranking button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -5px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  padding-top: -10px;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Ranking p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.MobileHeader-Coin button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -25px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Coin p {
  position: relative;
  z-index: 2;
  display: flex;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.MobileHeader-Event button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -45px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Event p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.MobileHeader-Notice button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -65px;
  left: 5px;
  justify-content: left;
  align-items: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Notice p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.MobileHeader-Block button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -85px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Block p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: black;
}

.MobileHeader-Logout button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -105px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 25px;
  -webkit-tap-highlight-color: transparent;
}

.MobileHeader-Logout p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #f4361e;
}

.MobileHeader-Login button {
  position: absolute;
  border-radius: 8px;
  border: none;
  background-color: #229ec5;
  color: #ffffff;
  font-family: Pretendard, serif;
  font-size: 12px;
  margin-top: 2px;
  width: 57px;
  height: 25px;
  right: 5px;
  cursor: pointer;
}

.MobileHeader-Avatar img {
  position: absolute;
  right: 20px;
  top: 10px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
  cursor: pointer;
}

.MobileHeader-Avatar img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}
