.Report {
  background-color: #fafafa;
}

.Report-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Report-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Report-DislikeChecked {
  position: absolute;
  left: 35vw;
  top: 20vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Report-DislikeChecked input {
  left: 0;
  zoom: 1.5;
}

.Report-DislikeChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Report-HarmfulChecked {
  position: absolute;
  left: 35vw;
  top: 25vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Report-HarmfulChecked input {
  left: 0;
  zoom: 1.5;
}

.Report-HarmfulChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Report-AdChecked {
  position: absolute;
  left: 35vw;
  top: 30vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Report-AdChecked input {
  left: 0;
  zoom: 1.5;
}

.Report-AdChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Report-PornChecked {
  position: absolute;
  left: 35vw;
  top: 35vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Report-PornChecked input {
  left: 0;
  zoom: 1.5;
}

.Report-PornChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Report-EtcChecked {
  position: absolute;
  left: 35vw;
  top: 40vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Report-EtcChecked input {
  left: 0;
  zoom: 1.5;
}

.Report-EtcChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Report-EtcInputBody textarea {
  position: absolute;
  left: 34vw;
  top: 45vh;
  resize: none;
  width: 29.5vw;
  height: 60px;
  margin-top: 18px;
  margin-left: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  word-wrap: break-word;
  outline: none;
}

.Report-Continue button {
  position: absolute;
  top: 63vh;
  left: 35vw;
  width: 30vw;
  height: 5vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}
