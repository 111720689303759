.Notice {
  background-color: #fafafa;
}

.Notice-Main {
  position: relative;
  width: 50vw;
  left: 25vw;
  margin-top: 50px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  border-radius: 24px;
  background-color: #ffffff;
}

.Notice-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  left: 5vw;
  width: 40vw;
  border-bottom: 2px solid #229ec5;
  margin-bottom: 20px;
}

.Notice-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Notice-Title p {
  position: relative;
  top: -10px;
  width: 40vw;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Notice-Write button {
  position: relative;
  left: 200px;
  top: 5px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Notice-LoadingBar {
  position: absolute;
  top: 0px;
  width: 50vw;
}

.Notice-Documents {
  width: 45vw;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 2.5vw;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  background-color: #f8f9fa;
}

.Notice-Document {
  position: relative;
  width: 45vw;
  background-color: transparent;
}

.Notice-DocumentHeader {
  position: relative;
  display: flex;
}

.Notice-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Notice-Nickname button {
  position: relative;
  margin-top: 29px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.Notice-Time {
  position: relative;
  margin-top: 14px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.Notice-Delete img {
  position: absolute;
  display: flex;
  width: 25px;
  top: 15px;
  left: 41vw;
  z-index: 1;
  cursor: pointer;
}

.Notice-Content {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 18px;
  font-weight: 550;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  word-wrap: break-word;
  margin-left: 24px;
  margin-top: -10px;
  padding-right: 26px;
}

.Notice-Content img {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  border-radius: 12px;
  word-wrap: break-word;
}

.Notice-Content iframe {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  height: 400px;
  border-radius: 12px;
  word-wrap: break-word;
}
