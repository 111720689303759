.Eula-Title {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20vh;
  left: 40vw;
  width: 20vw;
}

.Eula-Title img {
  width: 20vw;
}

.Eula-Title h1 {
  width: 30vw;
  font-family: Pretendard, serif;
  font-size: 24px;
}

.Eula-Title p {
  margin-top: -10px;
  width: 30vw;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Eula-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 15vh;
  left: 20vw;
  width: 20vw;
  gap: 30px;
}

.Eula-Background img {
  width: 38vw;
}

.Eula-EulaChecked {
  position: absolute;
  left: 35vw;
  top: 47vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Eula-EulaChecked input {
  left: 0;
  zoom: 1.5;
}

.Eula-EulaChecked p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Eula-EulaChecked button {
  position: absolute;
  right: 0;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}

.Eula-AgeChecked {
  position: absolute;
  left: 35vw;
  top: 52vh;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Eula-AgeChecked input {
  left: 0;
  zoom: 1.5;
}

.Eula-AgeChecked p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Eula-AgeChecked button {
  position: absolute;
  right: 0;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}

.Eula-Continue button {
  position: absolute;
  top: 63vh;
  left: 35vw;
  width: 30vw;
  height: 5vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
}
