@import url("https://cdn.jsdelivr.net/npm/quill@2/dist/quill.snow.css");

.Write {
  background-color: #fafafa;
}

.Write-Title {
  position: relative;
  top: 5vh;
  left: 30vw;
  width: 40vw;
}

.Write-Title h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Write-LoadingBar {
  position: relative;
  width: 40vw;
  top: 0.5vh;
}

.Write-HashtagInput {
  position: relative;
  top: 6vh;
  left: 30vw;
  width: calc(40vw - 50px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  border-top: 1px solid #d4d4d8;
  border-bottom: 1px solid #d4d4d8;
}

.Write-HashtagInput img {
  width: 18px;
  height: 18px;
}

.Write-HashtagInput input {
  flex-grow: 1;
  height: 15px;
  border: none;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
  background-color: transparent;
  outline: none;
}

.Write-Hashtags {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  position: relative;
  left: calc(30vw - 50px);
  top: 6vh;
  width: calc(40vw);
}

.Write-Hashtags ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 7vh;
}

.Write-Hashtags-tag {
  font-family: Pretendard, serif;
  font-size: 16px;
  padding: 10px;
  color: #767676;
}

.Write-Quill {
  position: relative;
  margin-top: 2vh;
  left: 30vw;
  width: 40vw;
}

.Write-Submit button {
  position: relative;
  margin-top: 10vh;
  margin-bottom: 100px;
  width: 20vw;
  height: 40px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 10px;
  cursor: pointer;
}

.Write-Quill .ql-editor {
  font-family: Pretendard, serif;
  font-size: 16px;
  min-height: 50vh !important;
}

.Write-Quill .ql-toolbar {
  position: relative !important;
  left: -18vw !important;
  top: -3vh !important;
  border: 0 !important;
}

.Write-Quill .ql-container.ql-snow {
  top: -2vh !important;
  border-radius: 24px !important;
  border: 1px solid #d4d4d8 !important;
  padding-bottom: 20px !important;
  min-height: 50vh !important;
}

.Write-Quill .ql-editor img {
  position: relative;
  display: block;
  width: 80%;
  left: 10%;
}

.Write-Quill .ql-editor .ql-video {
  position: relative;
  display: block;
  width: 80%;
  left: 10%;
  height: 30vh;
}
