.MobileRead {
    background-color: #fafafa;
    overflow: hidden;
}

.MobileRead-Title {
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    left: 10vw;
    width: 80vw;
}

.MobileRead-Title img {
    object-fit: cover;
    width: 40px;
}

.MobileRead-Title h1 {
    position: absolute;
    width: 80vw;
    margin-top: 10px;
    left: 1vw;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 500;
}

.MobileRead-Body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 95vw;
    margin-top: 5px;
    margin-left: 2.5vw;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-Body-Void {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 95vw;
    margin-top: 5px;
    margin-left: 2.5vw;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-Body-Void p {
    font-family: Pretendard, serif;
    font-size: 14px;
}

.MobileRead-Document {
    position: relative;
    width: 95vw;
}

.MobileRead-DocumentHeader {
    position: relative;
    display: flex;
}

.MobileRead-Avatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-Nickname button {
    position: relative;
    margin-top: 27px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.MobileRead-Time {
    position: relative;
    margin-top: 18px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: gray;
}

.MobileRead-Bookmark button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 26px;
    right: 40px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-Bookmark img {
    width: 18px;
    height: 18px;
}

.MobileRead-Misc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 23px;
    right: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-Misc img {
    width: 24px;
    height: 24px;
}

.MobileRead-MiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 70px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-MiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 40px;
    width: 110px;
    height: 40px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-Block {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -12px;
}

.MobileRead-Block img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-Block p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-Report {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 15px;
}

.MobileRead-Report img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-Report p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-Delete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -12px;
}

.MobileRead-Delete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-Delete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-Content {
    position: relative;
    font-family: Pretendard, serif;
    font-size: 16px;
    text-align: left;
    background-color: transparent;
    border: 1px solid transparent;
    word-wrap: break-word;
    margin-left: 18px;
    margin-top: -10px;
    padding-right: 26px;
    width: 88vw;
}

.MobileRead-Content img {
    position: relative;
    margin-top: 18px;
    left: -6px;
    width: 100%;
    border-radius: 12px;
    word-wrap: break-word;
}

.MobileRead-Content iframe {
    position: relative;
    margin-top: 18px;
    left: -6px;
    width: 100%;
    height: 30vh;
    border-radius: 12px;
    word-wrap: break-word;
}

.MobileRead-Hashtags ul {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: -20px;
}

.MobileRead-Hashtags li {
    list-style: none;
}

.MobileRead-Hashtag {
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    padding: 2px;
    width: 120px;
    height: 20px;
}

.MobileRead-Hashtag img {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;
}

.MobileRead-Hashtag button {
    position: relative;
    top: -20px;
    margin-left: 10px;
    background-color: transparent;
    border: none;
    border-radius: 24px;
    width: 150px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: left;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: black;
}

.MobileRead-Summary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 20px;
    margin-top: -15px;
    align-self: start;
}

.MobileRead-NumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-NumUps img {
    width: 20px;
    height: 9px;
}

.MobileRead-NumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-NumUps-Focus img {
    width: 22px;
    height: 10px;
}

.MobileRead-NumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.MobileRead-NumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-NumDowns img {
    width: 42px;
    height: 9px;
}

.MobileRead-NumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-NumDowns-Focus img {
    width: 46px;
    height: 10px;
}

.MobileRead-NumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.MobileRead-NumComments button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NumComments img {
    width: 16px;
    height: 16px;
}

.MobileRead-NumComments p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NumTokens button {
    position: relative;
    display: flex;
    top: -1px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NumTokens p {
    font-family: Pretendard, serif;
    font-size: 12px;
    font-weight: 800;
    color: #767676;
}

.MobileRead-ComingSoon {
    position: relative;
}

.MobileRead-ComingSoon img {
    width: 40px;
    height: 40px;
}

.MobileRead-CommentInput {
    display: flex;
    flex-direction: row;
    width: 95vw;
    border-top: 1px solid #d4d4d8;
}

.MobileRead-CommentInputAvatar img {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-CommentInputBody {
    display: flex;
    flex-direction: column;
}

.MobileRead-CommentInputBody textarea {
    position: relative;
    resize: none;
    width: calc(95vw - 140px);
    height: 40px;
    margin-top: 18px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    border: 1px solid #fffefc;
    border-radius: 10px;
    word-wrap: break-word;
    outline: none;
}

.MobileRead-CommentInputBody button {
    position: absolute;
    right: 10px;
    margin-top: 15px;
    background-color: #229ec5;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: #fffefc;
    border: 1px solid #fffefc;
    border-radius: 10px;
    width: 50px;
    height: 30px;
}

.MobileRead-LoadingBar {
    position: relative;
    width: 95vw;
    z-index: 1;
}

.MobileRead-Comment {
    display: flex;
    flex-direction: column;
    width: 95vw;
    border-top: 1px solid #d4d4d8;
}

.MobileRead-CommentHeader {
    position: relative;
    display: flex;
    width: 99%;
}

.MobileRead-Best img {
    margin-top: 22px;
    margin-left: 11px;
    width: 34px;
    height: 14px;
}

.MobileRead-CommentAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-CommentNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.MobileRead-CommentTime {
    position: relative;
    margin-top: 10px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: gray;
}

.MobileRead-CommentMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    right: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-CommentMisc img {
    width: 24px;
    height: 24px;
}

.MobileRead-CommentMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 65px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-CommentMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 16px;
    margin-top: 40px;
    width: 110px;
    height: 35px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-CommentBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -11px;
}

.MobileRead-CommentBlock img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-CommentBlock p {
    position: relative;
    display: flex;
    top: -12px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-CommentReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 20px;
}

.MobileRead-CommentReport img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-CommentReport p {
    position: relative;
    display: flex;
    top: -13px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-CommentDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -11px;
}

.MobileRead-CommentDelete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-CommentDelete p {
    position: relative;
    display: flex;
    top: -12px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-CommentBody {
    position: relative;
    width: calc(95vw - 80px);
    font-family: Pretendard, serif;
    font-size: 14px;
    text-align: justify;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    margin-left: 56px;
    margin-top: -30px;
}

.MobileRead-CommentSummary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 52px;
    align-self: start;
    margin-top: -15px;
}

.MobileRead-CommentNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-CommentNumUps img {
    width: 20px;
    height: 9px;
}

.MobileRead-CommentNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-CommentNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-CommentNumUps-Focus img {
    width: 22px;
    height: 10px;
}

.MobileRead-CommentNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.MobileRead-CommentNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-CommentNumDowns img {
    width: 42px;
    height: 9px;
}

.MobileRead-CommentNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-CommentNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-CommentNumDowns-Focus img {
    width: 46px;
    height: 10px;
}

.MobileRead-CommentNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.MobileRead-CommentNumReplies button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-CommentNumReplies img {
    width: 16px;
    height: 16px;
}

.MobileRead-CommentNumReplies p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-CommentNumTokens button {
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-CommentNumTokens p {
    font-family: Pretendard, serif;
    font-size: 12px;
    font-weight: 800;
    color: #767676;
}

.MobileRead-MobileReadMoreComments button {
    width: 90px;
    height: 4vh;
    background-color: #229ec5;
    color: #fffefc;
    font-family: Pretendard, serif;
    font-size: 13px;
    border: 1px solid #fffefc;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px auto;
}

.MobileRead-ReplyInput {
    position: relative;
    width: 95vw;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #d4d4d8;
    background-color: #f8f8fa;
    border-bottom: 1px solid #fffefc;
    border-radius: 25px;
}

.MobileRead-ReplyImage img {
    position: relative;
    margin-left: 25px;
    margin-top: 20px;
    height: 20px;
}

.MobileRead-ReplyInputAvatar img {
    position: relative;
    margin-top: 15px;
    margin-left: 2px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-ReplyInputBody {
    display: flex;
    flex-direction: column;
}

.MobileRead-ReplyInputBody textarea {
    position: relative;
    resize: none;
    width: calc(95vw - 180px);
    height: 40px;
    margin-top: 24px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    border: 1px solid #f8f8fa;
    border-radius: 10px;
    word-wrap: break-word;
    outline: none;
    background-color: #f8f8fa;
}

.MobileRead-ReplyInputBody button {
    position: absolute;
    right: 10px;
    margin-top: 20px;
    background-color: #229ec5;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: #fffefc;
    border: 1px solid #fffefc;
    border-radius: 10px;
    width: 50px;
    height: 30px;
}

.MobileRead-Reply {
    background-color: #f8f8fa;
    border-top: 1px solid #d4d4d8;
    border-radius: 24px;
}

.MobileRead-ReplyHeader {
    position: relative;
    display: flex;
}

.MobileRead-ReplyAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 2px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-ReplyNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.MobileRead-ReplyTime {
    position: relative;
    margin-top: 11px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: gray;
}

.MobileRead-ReplyMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    right: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-ReplyMisc img {
    width: 24px;
    height: 24px;
}

.MobileRead-ReplyMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 70px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-ReplyMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 35px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-ReplyBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -11px;
}

.MobileRead-ReplyBlock img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-ReplyBlock p {
    position: relative;
    display: flex;
    top: -12px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-ReplyReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 20px;
}

.MobileRead-ReplyReport img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-ReplyReport p {
    position: relative;
    display: flex;
    top: -12px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-ReplyDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -11px;
}

.MobileRead-ReplyDelete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.MobileRead-ReplyDelete p {
    position: relative;
    display: flex;
    top: -12px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-ReplyBody {
    position: relative;
    width: calc(95vw - 120px);
    font-family: Pretendard, serif;
    font-size: 14px;
    text-align: justify;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    margin-left: 95px;
    margin-top: -30px;
}

.MobileRead-ReplySummary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 92px;
    align-self: start;
    margin-top: -15px;
}

.MobileRead-ReplyNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-ReplyNumUps img {
    width: 20px;
    height: 9px;
}

.MobileRead-ReplyNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-ReplyNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-ReplyNumUps-Focus img {
    width: 22px;
    height: 10px;
}

.MobileRead-ReplyNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.MobileRead-ReplyNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-ReplyNumDowns img {
    width: 42px;
    height: 9px;
}

.MobileRead-ReplyNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-ReplyNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.MobileRead-ReplyNumDowns-Focus img {
    width: 46px;
    height: 10px;
}

.MobileRead-ReplyNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.MobileRead-Split img {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 85vw;
    height: 2px;
}

.MobileRead-NextTitle h1 {
    position: absolute;
    width: 100vw;
    margin-top: -10px;
    left: 1vw;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 500;
}

.MobileRead-NextDocuments {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 2.5vw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.MobileRead-NextDocument {
    position: relative;
    width: 95vw;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
    margin-top: 5px;
}

.MobileRead-NextDocumentHeader {
    position: relative;
    display: flex;
}

.MobileRead-NextAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 32px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.MobileRead-NextNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.MobileRead-NextTime {
    position: relative;
    margin-top: 10px;
    margin-left: 2px;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: gray;
}

.MobileRead-NextBookmark button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    right: 40px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextBookmark img {
    width: 18px;
    height: 18px;
}

.MobileRead-NextMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    right: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextMisc img {
    width: 24px;
    height: 24px;
}

.MobileRead-NextMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 50px;
    width: 110px;
    height: 70px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-NextMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    right: 15px;
    margin-top: 40px;
    width: 110px;
    height: 40px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.MobileRead-NextBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.MobileRead-NextBlock img {
    position: relative;
    display: flex;
    top: -2px;
    width: 20px;
    height: 20px;
}

.MobileRead-NextBlock p {
    position: relative;
    display: flex;
    top: -15px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-NextReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 30px;
}

.MobileRead-NextReport img {
    position: relative;
    display: flex;
    top: -8px;
    width: 20px;
    height: 20px;
}

.MobileRead-NextReport p {
    position: relative;
    display: flex;
    top: -20px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-NextDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.MobileRead-NextDelete img {
    position: relative;
    display: flex;
    top: -3px;
    width: 20px;
    height: 20px;
}

.MobileRead-NextDelete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 5px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: black;
}

.MobileRead-NextThumbnailContent {
    position: relative;
    background-color: transparent;
    margin-left: 50px;
    margin-top: -20px;
    align-self: start;
    width: calc(85vw + 15px);
}

.MobileRead-NextThumbnailContent button {
    position: relative;
    width: 103%;
    font-family: Pretendard, serif;
    font-size: 15px;
    align-self: start;
    text-align: left;
    padding-right: 50px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    color: black;
}

.MobileRead-NextThumbnailContent img {
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.MobileRead-NextThumbnailContent iframe {
    width: 100%;
    height: 30vh;
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.MobileRead-NextHashtags ul {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 20px;
}

.MobileRead-NextHashtags li {
    list-style: none;
}

.MobileRead-NextHashtag {
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    padding: 2px;
    width: 120px;
    height: 20px;
}

.MobileRead-NextHashtag img {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;
    z-index: 1;
}

.MobileRead-NextHashtag button {
    position: relative;
    top: -20px;
    margin-left: 10px;
    background-color: transparent;
    border: none;
    border-radius: 24px;
    width: 150px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: left;
    font-family: Pretendard, serif;
    font-size: 12px;
    color: black;
}

.MobileRead-NextReadMore button {
    position: relative;
    left: calc(47.5vw - 50px);
    display: flex;
    margin: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextReadMore img {
    width: 90px;
    height: 20px;
}

.MobileRead-NextSummary {
    display: flex;
    flex-direction: row;
    position: relative;
    left: calc(47.5vw - 140px);
    align-self: start;
}

.MobileRead-NextNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumUps img {
    width: 20px;
    height: 9px;
}

.MobileRead-NextNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NextNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumUps-Focus img {
    width: 22px;
    height: 10px;
}

.MobileRead-NextNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.MobileRead-NextNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumDowns img {
    width: 42px;
    height: 9px;
}

.MobileRead-NextNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NextNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumDowns-Focus img {
    width: 46px;
    height: 10px;
}

.MobileRead-NextNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.MobileRead-NextNumComments button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumComments img {
    width: 16px;
    height: 16px;
}

.MobileRead-NextNumComments p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.MobileRead-NextNumTokens button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.MobileRead-NextNumTokens p {
    font-family: Pretendard, serif;
    font-size: 12px;
    font-weight: 800;
    color: #767676;
}

.MobileRead-MobileReadMoreReplies {
    background-color: #f8f8fa;
    border-radius: 24px;
}

.MobileRead-MobileReadMoreReplies button {
    width: 90px;
    height: 4vh;
    background-color: #229ec5;
    color: #fffefc;
    font-family: Pretendard, serif;
    font-size: 13px;
    border: 1px solid #fffefc;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px auto;
}
