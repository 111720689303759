.EmailVerification-Title h1 {
  margin-top: 40px;
  font-family: Pretendard, serif;
  font-size: 18px;
}

.EmailVerification-Title p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EmailVerification-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 15vh;
  left: 20vw;
  width: 20vw;
  gap: 30px;
}

.EmailVerification-Background img {
  width: 38vw;
}

.EmailVerification-Inputs {
  position: absolute;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  width: 45vw;
  height: 45vh;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.EmailVerification-EmailInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EmailVerification-EmailInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.EmailVerification-EmailInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EmailVerification-EmailInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EmailVerification-EmailInputForm {
  margin-left: 10px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EmailVerification-EmailInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EmailVerification-PasswordInput {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.EmailVerification-PasswordInputHint {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.EmailVerification-PasswordInputHint h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.EmailVerification-PasswordInputHint p {
  margin-left: 2px;
  margin-top: 8px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #229ec5;
}

.EmailVerification-PasswordInputForm {
  margin-left: 10px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.EmailVerification-PasswordInputForm input {
  position: relative;
  margin-left: 10px;
  width: 43vw;
  height: 30px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.EmailVerification-ContinueActive button {
  position: absolute;
  margin-top: 40vh;
  margin-left: -21.5vw;
  width: 43vw;
  height: 5vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}

.EmailVerification-ContinueInactive button {
  position: absolute;
  margin-top: 40vh;
  margin-left: -21.5vw;
  width: 43vw;
  height: 5vh;
  background-color: #d4d4d8;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}
