.Read {
    background-color: #fafafa;
}

.Read-Title {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    left: 30vw;
    width: 40vw;
}

.Read-Title img {
    object-fit: cover;
    width: 48px;
}

.Read-Title h1 {
    position: absolute;
    width: 20vw;
    left: 10vw;
    font-family: Pretendard, serif;
    font-size: 16px;
    font-weight: 500;
}

.Read-Body {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 45vw;
    margin-top: 5px;
    margin-left: 27.5vw;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-Body-Void {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 45vw;
    margin-top: 5px;
    margin-left: 27.5vw;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-Body-Void p {
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-Document {
    position: relative;
    width: 45vw;
}

.Read-DocumentHeader {
    position: relative;
    display: flex;
}

.Read-Avatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-Nickname button {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
}

.Read-Time {
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: gray;
}

.Read-Bookmark button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 28px;
    right: 60px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-Bookmark img {
    width: 18px;
    height: 18px;
}

.Read-Misc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    right: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-Misc img {
    width: 24px;
    height: 24px;
}

.Read-MiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 80px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-MiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 45px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-Block {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -15px;
}

.Read-Block img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-Block p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-Report {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 20px;
}

.Read-Report img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-Report p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-Delete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -15px;
}

.Read-Delete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-Delete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-Content {
    position: relative;
    font-family: Pretendard, serif;
    font-size: 16px;
    text-align: left;
    background-color: transparent;
    border: 1px solid transparent;
    word-wrap: break-word;
    margin-left: 24px;
    margin-top: -10px;
    padding-right: 26px;
}

.Read-Content img {
    position: relative;
    margin-top: 18px;
    left: 2px;
    width: 100%;
    border-radius: 12px;
    word-wrap: break-word;
}

.Read-Content iframe {
    position: relative;
    margin-top: 18px;
    left: 2px;
    width: 100%;
    height: 400px;
    border-radius: 12px;
    word-wrap: break-word;
}

.Read-Hashtags ul {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-left: -18px;
}

.Read-Hashtags li {
    list-style: none;
}

.Read-Hashtag {
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    padding: 2px;
}

.Read-Hashtag img {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;
}

.Read-Hashtag button {
    position: relative;
    margin-left: -2px;
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    width: 120px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: left;
}

.Read-Summary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 20px;
    margin-top: -15px;
    align-self: start;
}

.Read-NumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumUps img {
    width: 22px;
    height: 10px;
}

.Read-NumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumUps-Focus img {
    width: 24px;
    height: 11px;
}

.Read-NumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.Read-NumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumDowns img {
    width: 44px;
    height: 10px;
}

.Read-NumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumDowns-Focus img {
    width: 48px;
    height: 11px;
}

.Read-NumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.Read-NumComments button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumComments img {
    width: 18px;
    height: 18px;
}

.Read-NumComments p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NumTokens button {
    position: relative;
    display: flex;
    top: -5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NumTokens p {
    font-family: Pretendard, serif;
    font-size: 13px;
    font-weight: 800;
    color: #767676;
}

.Read-ComingSoon {
    position: relative;
}

.Read-ComingSoon img {
    width: 50px;
    height: 50px;
}

.Read-CommentInput {
    display: flex;
    flex-direction: row;
    width: 45vw;
    border-top: 1px solid #d4d4d8;
}

.Read-CommentInputAvatar img {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-CommentInputBody {
    display: flex;
    flex-direction: column;
}

.Read-CommentInputBody textarea {
    position: relative;
    resize: none;
    width: calc(45vw - 180px);
    height: 60px;
    margin-top: 18px;
    margin-left: 20px;
    font-family: Pretendard, serif;
    font-size: 16px;
    border: 1px solid #fffefc;
    border-radius: 10px;
    word-wrap: break-word;
    outline: none;
}

.Read-CommentInputBody button {
    position: absolute;
    right: 20px;
    margin-top: 20px;
    background-color: #229ec5;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: #fffefc;
    border: 1px solid #fffefc;
    border-radius: 10px;
    width: 60px;
    height: 40px;
}

.Read-LoadingBar {
    position: relative;
    width: 45vw;
    z-index: 1;
}

.Read-Comment {
    display: flex;
    flex-direction: column;
    width: 45vw;
    border-top: 1px solid #d4d4d8;
}

.Read-CommentHeader {
    position: relative;
    display: flex;
    width: 99%;
}

.Read-Best img {
    margin-top: 22px;
    margin-left: 11px;
    width: 40px;
    height: 17px;
}

.Read-CommentAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-CommentNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
    color: black;
}

.Read-CommentTime {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: gray;
}

.Read-CommentMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    right: 16px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentMisc img {
    width: 24px;
    height: 24px;
}

.Read-CommentMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 80px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-CommentMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 45px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-CommentBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -8px;
}

.Read-CommentBlock img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-CommentBlock p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-CommentReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 25px;
}

.Read-CommentReport img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-CommentReport p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-CommentDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -8px;
}

.Read-CommentDelete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-CommentDelete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-CommentBody {
    position: relative;
    width: calc(45vw - 120px);
    font-family: Pretendard, serif;
    font-size: 16px;
    text-align: justify;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    margin-left: 78px;
    margin-top: -20px;
}

.Read-CommentSummary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 73px;
    align-self: start;
    margin-top: -15px;
}

.Read-CommentNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumUps img {
    width: 22px;
    height: 10px;
}

.Read-CommentNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-CommentNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumUps-Focus img {
    width: 24px;
    height: 11px;
}

.Read-CommentNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.Read-CommentNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumDowns img {
    width: 44px;
    height: 10px;
}

.Read-CommentNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-CommentNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumDowns-Focus img {
    width: 48px;
    height: 11px;
}

.Read-CommentNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.Read-CommentNumReplies button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumReplies img {
    width: 18px;
    height: 18px;
}

.Read-CommentNumReplies p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-CommentNumTokens button {
    position: relative;
    top: -5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-CommentNumTokens p {
    font-family: Pretendard, serif;
    font-size: 13px;
    font-weight: 800;
    color: #767676;
}

.Read-ReadMoreComments button {
    width: 10vw;
    height: 4vh;
    background-color: #229ec5;
    color: #fffefc;
    font-family: Pretendard, serif;
    font-size: 16px;
    border: 1px solid #fffefc;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px auto;
}

.Read-ReplyInput {
    position: relative;
    width: 45vw;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #d4d4d8;
    background-color: #f8f8fa;
    border-bottom: 1px solid #fffefc;
    border-radius: 25px;
}

.Read-ReplyImage img {
    position: relative;
    margin-left: 40px;
    margin-top: 20px;
    height: 24px;
}

.Read-ReplyInputAvatar img {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-ReplyInputBody {
    display: flex;
    flex-direction: column;
}

.Read-ReplyInputBody textarea {
    position: relative;
    resize: none;
    width: calc(45vw - 230px);
    height: 60px;
    margin-top: 18px;
    margin-left: 20px;
    font-family: Pretendard, serif;
    font-size: 16px;
    border: 1px solid #f8f8fa;
    border-radius: 10px;
    word-wrap: break-word;
    outline: none;
    background-color: #f8f8fa;
}

.Read-ReplyInputBody button {
    position: absolute;
    right: 20px;
    margin-top: 20px;
    background-color: #229ec5;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: #fffefc;
    border: 1px solid #fffefc;
    border-radius: 10px;
    width: 60px;
    height: 40px;
}

.Read-Reply {
    background-color: #f8f8fa;
    border-top: 1px solid #d4d4d8;
    border-radius: 24px;
}

.Read-ReplyHeader {
    position: relative;
    display: flex;
}

.Read-ReplyAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-ReplyNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
}

.Read-ReplyTime {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: gray;
}

.Read-ReplyMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    right: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-ReplyMisc img {
    width: 24px;
    height: 24px;
}

.Read-ReplyMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 80px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-ReplyMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 50px;
    width: 120px;
    height: 45px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-ReplyBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -15px;
}

.Read-ReplyBlock img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-ReplyBlock p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-ReplyReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 20px;
}

.Read-ReplyReport img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-ReplyReport p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-ReplyDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -15px;
}

.Read-ReplyDelete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-ReplyDelete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-ReplyBody {
    position: relative;
    width: calc(45vw - 180px);
    font-family: Pretendard, serif;
    font-size: 16px;
    text-align: justify;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
    margin-left: 130px;
    margin-top: -30px;
}

.Read-ReplySummary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 124px;
    align-self: start;
    margin-top: -15px;
}

.Read-ReplyNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-ReplyNumUps img {
    width: 22px;
    height: 10px;
}

.Read-ReplyNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-ReplyNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-ReplyNumUps-Focus img {
    width: 24px;
    height: 11px;
}

.Read-ReplyNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.Read-ReplyNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-ReplyNumDowns img {
    width: 44px;
    height: 10px;
}

.Read-ReplyNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-ReplyNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-ReplyNumDowns-Focus img {
    width: 48px;
    height: 11px;
}

.Read-ReplyNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.Read-Split img {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 35vw;
    height: 2px;
}

.Read-NextTitle h1 {
    position: absolute;
    width: 100vw;
    margin-top: -10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    font-weight: 500;
}

.Read-NextDocuments {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 35px;
    margin-bottom: 40px;
    margin-left: 27.5vw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Read-NextDocument {
    position: relative;
    width: 45vw;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
    margin-top: 5px;
}

.Read-NextDocumentHeader {
    position: relative;
    display: flex;
}

.Read-NextAvatar img {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    aspect-ratio: 1;
    object-fit: cover;
    width: 36px;
    border-radius: 50%;
    align-self: start;
    border: 3px solid #f1f1f5;
}

.Read-NextNickname button {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    font-weight: 550;
    background-color: transparent;
    border: 1px solid transparent;
    text-align: left;
}

.Read-NextTime {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 16px;
    color: gray;
}

.Read-NextBookmark button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    right: 60px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextBookmark img {
    width: 18px;
    height: 18px;
}

.Read-NextMisc button {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    right: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextMisc img {
    width: 24px;
    height: 24px;
}

.Read-NextMiscFocus {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 40px;
    width: 120px;
    height: 80px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-NextMiscFocus-Mine {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    left: 42vw;
    margin-top: 40px;
    width: 120px;
    height: 45px;
    border-radius: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #d4d4d8;
    background-color: #ffffff;
}

.Read-NextBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.Read-NextBlock img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-NextBlock p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-NextReport {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: 30px;
}

.Read-NextReport img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-NextReport p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-NextDelete {
    position: relative;
    display: flex;
    flex-direction: row;
    left: 40px;
    top: -5px;
}

.Read-NextDelete img {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
}

.Read-NextDelete p {
    position: relative;
    display: flex;
    top: -15px;
    left: 10px;
    width: 100px;
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-NextThumbnailContent {
    position: relative;
    background-color: transparent;
    margin-left: 70px;
    margin-top: -20px;
    align-self: start;
}

.Read-NextThumbnailContent button {
    position: relative;
    width: 100%;
    font-family: Pretendard, serif;
    font-size: 16px;
    align-self: start;
    text-align: start;
    padding-right: 50px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    word-wrap: break-word;
}

.Read-NextThumbnailContent img {
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.Read-NextThumbnailContent iframe {
    width: 99%;
    height: 400px;
    margin-top: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
}

.Read-NextHashtags ul {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-left: 34px;
}

.Read-NextHashtags li {
    list-style: none;
}

.Read-NextHashtag {
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    padding: 2px;
}

.Read-NextHashtag img {
    position: relative;
    top: 2px;
    width: 14px;
    height: 14px;
}

.Read-NextHashtag button {
    position: relative;
    margin-left: -2px;
    background-color: #f8f8fa;
    border: 1px solid #f8f8fa;
    border-radius: 24px;
    width: 120px;
    word-wrap: break-word;
    cursor: pointer;
    text-align: left;
}

.Read-NextReadMore button {
    position: relative;
    left: calc(22.5vw - 60px);
    display: flex;
    margin: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextReadMore img {
    width: 90px;
    height: 20px;
}

.Read-NextSummary {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 73px;
    align-self: start;
}

.Read-NextNumUps button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumUps img {
    width: 22px;
    height: 10px;
}

.Read-NextNumUps p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NextNumUps-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumUps-Focus img {
    width: 24px;
    height: 11px;
}

.Read-NextNumUps-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #f14b4b;
}

.Read-NextNumDowns button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumDowns img {
    width: 44px;
    height: 10px;
}

.Read-NextNumDowns p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NextNumDowns-Focus button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumDowns-Focus img {
    width: 48px;
    height: 11px;
}

.Read-NextNumDowns-Focus p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #4bacf1;
}

.Read-NextNumComments button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumComments img {
    width: 18px;
    height: 18px;
}

.Read-NextNumComments p {
    margin-left: 10px;
    font-family: Pretendard, serif;
    font-size: 14px;
    color: #767676;
}

.Read-NextNumTokens button {
    position: relative;
    top: -5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
}

.Read-NextNumTokens p {
    font-family: Pretendard, serif;
    font-size: 16px;
}

.Read-NextNumTokens p {
    font-family: Pretendard, serif;
    font-size: 13px;
    font-weight: 800;
    color: #767676;
}

.Read-ReadMoreReplies {
    background-color: #f8f8fa;
    border-radius: 24px;
}

.Read-ReadMoreReplies button {
    width: 10vw;
    height: 4vh;
    background-color: #229ec5;
    color: #fffefc;
    font-family: Pretendard, serif;
    font-size: 16px;
    border: 1px solid #fffefc;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px auto;
}
