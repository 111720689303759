.Header {
  border-bottom: 3px solid #f1f1f5;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}

.Header-Logo img {
  aspect-ratio: 4;
  object-fit: contain;
  width: 180px;
  max-width: 100%;
  margin-top: -5px;
  cursor: pointer;
}

.Header-Logo img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Navigation {
  position: absolute;
  display: flex;
  color: #767676;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: center;
  width: 50vw;
  left: calc(50vw - 200px);
  margin-top: -15px;
}

.Header-Recent button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Recent button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Recent-Focus {
  display: flex;
  flex-direction: column;
}

.Header-Recent-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Recent-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}

.Header-Following button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Following button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Following-Focus {
  display: flex;
  flex-direction: column;
}

.Header-Following-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Following-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Following-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 2px;
}

.Header-Random button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Random button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Random-Focus {
  display: flex;
  flex-direction: column;
}

.Header-Random-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Random-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Random-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 40px;
  height: 2px;
}

.Header-Profile button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Profile button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Profile-Focus {
  display: flex;
  flex-direction: column;
}

.Header-Profile-Focus button {
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-weight: 550;
  font-size: 16px;
  cursor: pointer;
}

.Header-Profile-Focus button:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-Profile-Focus img {
  align-self: center;
  margin-top: 3px;
  width: 60px;
  height: 2px;
}

.Header-Action {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.Header-Calculate button {
  position: absolute;
  right: 220px;
  top: 13px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Fist button {
  position: absolute;
  right: 170px;
  top: 13px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Menu {
  display: flex;
  flex-direction: column;
}

.Header-Menu img {
  position: absolute;
  aspect-ratio: 1;
  object-fit: contain;
  width: 24px;
  right: 134px;
  top: 15px;
  cursor: pointer;
}

.Header-Menu img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.Header-MenuFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: calc(100vw - 300px);
  top: 40px;
  width: 150px;
  height: 180px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Header-MenuFocusLogout {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: calc(100vw - 300px);
  top: 40px;
  width: 150px;
  height: 220px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Header-Ranking button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -5px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Ranking p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Header-Coin button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -25px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Coin p {
  position: relative;
  z-index: 2;
  display: flex;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Header-Event button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -45px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Event p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Header-Notice button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -65px;
  left: 5px;
  justify-content: left;
  align-items: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Notice p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Header-Block button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -85px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Block p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Header-Logout button {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: -105px;
  left: 5px;
  justify-content: left;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Header-Logout p {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #f4361e;
}

.Header-Login button {
  position: absolute;
  border-radius: 8px;
  border: none;
  background-color: #229ec5;
  color: #ffffff;
  font-family: Pretendard, serif;
  font-size: 16px;
  width: 70px;
  height: 35px;
  right: 32px;
  cursor: pointer;
}

.Header-Avatar img {
  position: absolute;
  right: 48px;
  top: 5px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
  cursor: pointer;
}

.Header-Avatar img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}
