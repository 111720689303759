@import url("https://cdn.jsdelivr.net/npm/quill@2/dist/quill.snow.css");

.MobileWrite {
  background-color: #fafafa;
}

.MobileWrite-Title {
  position: relative;
  top: 4vh;
  left: 10vw;
  width: 80vw;
}

.MobileWrite-Title h1 {
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileWrite-LoadingBar {
  position: relative;
  width: 100%;
  z-index: 1;
}

.MobileWrite-HashtagInput {
  position: relative;
  top: 6vh;
  left: 2.5vw;
  width: calc(95vw - 50px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  border-top: 1px solid #d4d4d8;
  border-bottom: 1px solid #d4d4d8;
}

.MobileWrite-HashtagInput img {
  width: 18px;
  height: 18px;
}

.MobileWrite-HashtagInput input {
  flex-grow: 1;
  height: 15px;
  border: none;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
  background-color: transparent;
  outline: none;
}

.MobileWrite-HashtagInput button {
  position: absolute;
  right: 20px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: #fffefc;
  border: 1px solid #fffefc;
  border-radius: 10px;
  width: 50px;
  height: 30px;
}

.MobileWrite-Hashtags {
  display: flex;
  text-align: left;
  gap: 8px;
  padding: 10px 24px;
  position: relative;
  left: calc(2.5vw - 50px);
  top: 4vh;
  width: calc(95vw);
}

.MobileWrite-Hashtags ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.MobileWrite-Hashtags-tag {
  font-family: Pretendard, serif;
  font-size: 14px;
  padding: 10px;
  color: #767676;
}

.MobileWrite-Quill {
  position: relative !important;
  top: 6vh;
  left: 2.5vw;
  width: 95vw;
  height: 40vh;
  background-color: #fafafa;
}

.MobileWrite-Submit button {
  position: relative;
  top: 15vh;
  width: 40vw;
  height: 40px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 250px;
}

.MobileWrite-Quill .ql-editor {
  position: relative !important;
  font-family: Pretendard, serif;
  font-size: 15px;
}

.MobileWrite-Quill .ql-toolbar {
  position: relative !important;
  left: -37vw !important;
  top: -5vh !important;
  border: 0 !important;
}

.MobileWrite-Quill .ql-container.ql-snow {
  position: relative !important;
  top: -5vh !important;
  border-radius: 24px !important;
  border: 1px solid #d4d4d8 !important;
  padding-bottom: 20px !important;
}

.MobileWrite-Quill .ql-editor img {
  position: relative;
  display: block;
  left: 10%;
  width: 80%;
}

.MobileWrite-Quill .ql-editor .ql-video {
  position: relative;
  display: block;
  left: 10%;
  width: 80%;
  max-height: 30vh;
  height: 30vh;
}
