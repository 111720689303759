@import url("https://cdn.jsdelivr.net/npm/quill@2/dist/quill.snow.css");

.NoticeWrite {
  background-color: #fafafa;
}

.NoticeWrite-Title {
  position: absolute;
  top: 10vh;
  left: 30vw;
  width: 40vw;
}

.NoticeWrite-Title h1 {
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.NoticeWrite-LoadingBar {
  position: absolute;
  width: 40vw;
  top: 4.5vh;
}

.NoticeWrite-Quill {
  position: absolute;
  top: 18vh;
  left: 30vw;
  width: 40vw;
  height: 50vh;
}

.NoticeWrite-Submit button {
  position: absolute;
  top: 74vh;
  left: 30vw;
  width: 40vw;
  height: 40px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 10px;
  cursor: pointer;
}

.ql-editor {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.ql-toolbar {
  position: absolute !important;
  left: -3vw !important;
  top: -3vh !important;
  border: 0 !important;
}

.ql-container.ql-snow {
  top: 3vh !important;
  border-radius: 24px !important;
  border: 1px solid #d4d4d8 !important;
  padding-bottom: 20px !important;
}

.ql-editor .ql-video {
  display: block;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
}
