.Following {
  background-color: #fafafa;
}

.Following-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 30vw;
  width: 40vw;
}

.Following-Title h1 {
  position: relative;
  width: 20vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 500;
}

.Following-LoadingBar {
  position: absolute;
  left: 30vw;
  width: 40vw;
}

.Following-Documents {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 27.5vw;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Following-Document {
  position: relative;
  width: 45vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.Following-DocumentHeader {
  position: relative;
  display: flex;
}

.Following-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Following-Nickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.Following-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.Following-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 60px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-Bookmark img {
  width: 18px;
  height: 18px;
}

.Following-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-Misc img {
  width: 24px;
  height: 24px;
}

.Following-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 80px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Following-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  left: 42vw;
  margin-top: 40px;
  width: 120px;
  height: 45px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Following-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Following-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Following-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Following-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 30px;
}

.Following-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Following-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Following-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.Following-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.Following-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Following-ThumbnailContent {
  position: relative;
  background-color: transparent;
  margin-left: 70px;
  margin-top: -20px;
  align-self: start;
}

.Following-ThumbnailContent button {
  position: relative;
  width: 100%;
  font-family: Pretendard, serif;
  font-size: 16px;
  align-self: start;
  text-align: start;
  padding-right: 50px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
}

.Following-ThumbnailContent img {
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Following-ThumbnailContent iframe {
  width: 99%;
  height: 400px;
  margin-top: 18px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
}

.Following-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 34px;
}

.Following-Hashtags li {
  list-style: none;
}

.Following-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
}

.Following-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.Following-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 120px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.Following-ReadMore button {
  position: relative;
  left: calc(22.5vw - 60px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-ReadMore img {
  width: 90px;
  height: 20px;
}

.Following-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 73px;
  align-self: start;
}

.Following-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumUps img {
  width: 22px;
  height: 10px;
}

.Following-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Following-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumUps-Focus img {
  width: 24px;
  height: 11px;
}

.Following-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.Following-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumDowns img {
  width: 44px;
  height: 10px;
}

.Following-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Following-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumDowns-Focus img {
  width: 48px;
  height: 11px;
}

.Following-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.Following-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumComments img {
  width: 18px;
  height: 18px;
}

.Following-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.Following-NumTokens button {
  position: relative;
  top: -4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Following-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Following-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 13px;
  font-weight: 800;
  color: #767676;
}

.Following-ComingSoon {
  position: relative;
}

.Following-ComingSoon img {
  width: 50px;
  height: 50px;
}
