.MobileLogin-Title {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 150px;
  left: 40vw;
  width: 20vw;
  gap: 30px;
}

.MobileLogin-Title img {
  width: min(40vw, 200px);
}

.MobileLogin-Background {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20vh;
  width: 20vw;
  gap: 30px;
}

.MobileLogin-Background img {
  width: 70vw;
}

.MobileLogin-Email {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 220px;
  left: 10vw;
  width: 80vw;
  height: 40px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  background-color: #fffefc;
}

.MobileLogin-Email img {
  position: absolute;
  top: 9px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.MobileLogin-Email input {
  position: absolute;
  top: 7px;
  left: 30px;
  width: calc(80vw - 45px);
  height: 25px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileLogin-Password {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 270px;
  left: 10vw;
  width: 80vw;
  height: 40px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  background-color: #fffefc;
}

.MobileLogin-Password img {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.MobileLogin-Password input {
  position: absolute;
  top: 7px;
  left: 30px;
  width: calc(80vw - 45px);
  height: 25px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.MobileLogin-SignIn button {
  position: absolute;
  top: 350px;
  left: 10vw;
  width: 80vw;
  height: 40px;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
}

.MobileLogin-Help {
  top: 410px;
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 15vw;
  justify-content: space-around;
}

.MobileLogin-Help img {
  width: 2px;
  height: 20px;
}

.MobileLogin-EmailVerification button {
  width: 35vw;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  color: #767676;
}

.MobileLogin-PasswordReset button {
  width: 35vw;
  background-color: transparent;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  color: #767676;
}

.MobileLogin-SignUp {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 390px;
  gap: 40px;
  justify-content: center;
}

.MobileLogin-SignUp p {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.MobileLogin-SignUp button {
  background-color: transparent;
  color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.MobileLogin-EasySignInTitle {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 410px;
  gap: 10px;
  justify-content: center;
}

.MobileLogin-EasySignInTitle img {
  width: 9vw;
  height: 1px;
}

.MobileLogin-EasySignInTitle p {
  margin-top: -8px;
}

.MobileLogin-EasySignIn {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 420px;
  gap: 30px;
  justify-content: center;
}

.MobileLogin-Naver img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.MobileLogin-Kakao img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.MobileLogin-Google img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.MobileLogin-Facebook img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.MobileLogin-Apple img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
