.Admin {
  background-color: #fafafa;
}

.Admin-ReportDocumentMessages {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -5px;
  margin-bottom: 40px;
  margin-left: calc(50vw - 350px);
  width: 700px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Admin-ReportDocumentMessages-Void {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-ReportCommentMessages {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -5px;
  margin-bottom: 40px;
  margin-left: calc(50vw - 350px);
  width: 700px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Admin-ReportCommentMessages-Void {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-ReportReplyMessages {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -5px;
  margin-bottom: 40px;
  margin-left: calc(50vw - 350px);
  width: 700px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Admin-ReportReplyMessages-Void {
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-ReportMessage {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
  width: 700px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.Admin-ReportMessage button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.Admin-Header {
  position: relative;
  display: flex;
}

.Admin-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Admin-Nickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.Admin-Time {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: gray;
}

.Admin-Trash button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.Admin-Trash img {
  width: 24px;
  height: 24px;
}

.Admin-DislikeChecked {
  position: relative;
  left: 80px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Admin-DislikeChecked input {
  left: 0;
  zoom: 1.5;
}

.Admin-DislikeChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-HarmfulChecked {
  position: relative;
  left: 80px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Admin-HarmfulChecked input {
  left: 0;
  zoom: 1.5;
}

.Admin-HarmfulChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-AdChecked {
  position: relative;
  left: 80px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Admin-AdChecked input {
  left: 0;
  zoom: 1.5;
}

.Admin-AdChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-PornChecked {
  position: relative;
  left: 80px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Admin-PornChecked input {
  left: 0;
  zoom: 1.5;
}

.Admin-PornChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-EtcChecked {
  position: relative;
  left: 80px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}

.Admin-EtcChecked input {
  left: 0;
  zoom: 1.5;
}

.Admin-EtcChecked p {
  position: absolute;
  left: 40px;
  font-family: Pretendard, serif;
  font-size: 16px;
}

.Admin-EtcInputBody textarea {
  position: relative;
  left: 0px;
  resize: none;
  width: 80%;
  height: 60px;
  margin-top: 18px;
  margin-left: 20px;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
  word-wrap: break-word;
  outline: none;
}

.Admin-SearchBar {
  position: relative;
  left: 30vw;
  width: 36.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  margin-top: 5px;
  border-top: 1px solid #d4d4d8;
  border-bottom: 1px solid #d4d4d8;
}

.Admin-SearchBar img {
  width: 18px;
  height: 18px;
}

.Admin-SearchBar input {
  flex-grow: 1;
  height: 15px;
  border: none;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  color: #767676;
  outline: none;
}

.Admin-NamedUser {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Admin-NamedUserAvatar img {
  position: relative;
  margin-top: 32px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 72px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.Admin-NamedUserName p {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  color: #505050;
}

.Admin-NamedUserNickname button {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}
