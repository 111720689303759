.MobileSidebar {
  position: fixed;
  z-index: 2;
}

.MobileSidebar-Base-Phone img {
  position: fixed;
  bottom: -20px;
  object-fit: contain;
  width: 100vw;
  left: 0;
}

.MobileSidebar-Base-Tablet img {
  position: fixed;
  bottom: -9vh;
  object-fit: contain;
  width: 100vw;
  left: 0;
}

.MobileSidebar-Home-Phone img {
  position: fixed;
  bottom: -0.5vh;
  left: 8vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Home-Tablet img {
  position: fixed;
  bottom: 0;
  left: 10vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Home-Phone img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Home-Tablet img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Search-Phone img {
  position: fixed;
  bottom: 3vh;
  left: 24vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Search-Tablet img {
  position: fixed;
  bottom: 5vh;
  left: 27vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Search-Phone img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Search-Tablet img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Plus-Phone img {
  position: fixed;
  bottom: 4.5vh;
  left: 43vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Plus-Tablet img {
  position: fixed;
  bottom: 7vh;
  left: 47vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Plus-Phone img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Plus-Tablet img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Bell-Phone img {
  position: fixed;
  bottom: 3vh;
  left: 63vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Bell-Tablet img {
  position: fixed;
  bottom: 5vh;
  left: 67vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Bell-Phone img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Bell-Tablet img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}

.MobileSidebar-Profile-Phone img {
  position: fixed;
  bottom: -0.5vh;
  left: 80vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Profile-Tablet img {
  position: fixed;
  bottom: 0.5vh;
  left: 83vw;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.MobileSidebar-Profile img:hover {
  background-color: #f8f8fa;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #f8f8fa;
}
