.MobileNotificationView {
  background-color: #fafafa;
  overflow: hidden;
}

.MobileNotificationView-Title {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 20vw;
  width: 80vw;
}

.MobileNotificationView-Title h1 {
  position: relative;
  width: 40vw;
  left: 10vw;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 500;
}

.MobileNotificationView-Message {
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 2.5vw;
  width: 95vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.MobileNotificationView-Message-Void {
  position: relative;
  margin-top: 5px;
  margin-bottom: 40px;
  margin-left: 2.5vw;
  width: 95vw;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
  margin-top: 5px;
}

.MobileNotificationView-Message-Void p {
  font-family: Pretendard, serif;
  font-size: 14px;
}

.MobileNotificationView-DocumentHeader {
  position: relative;
  display: flex;
}

.MobileNotificationView-Avatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  left: 0px;
  top: -8px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileNotificationView-Nickname button {
  position: relative;
  margin-top: 18px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 14px;
  font-weight: 550;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.MobileNotificationView-Time {
  position: relative;
  margin-top: 10px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: gray;
}

.MobileNotificationView-Bookmark button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  right: 40px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-Bookmark img {
  width: 18px;
  height: 18px;
}

.MobileNotificationView-Report button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-Report img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-Content {
  position: relative;
  font-family: Pretendard, serif;
  font-size: 16px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 14px;
  margin-top: -10px;
  padding-right: 26px;
}

.MobileNotificationView-Content img {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  border-radius: 12px;
  word-wrap: break-word;
}

.MobileNotificationView-Content iframe {
  position: relative;
  margin-top: 18px;
  left: 2px;
  width: 100%;
  height: 30vh;
  border-radius: 12px;
  word-wrap: break-word;
}

.MobileNotificationView-Hashtags ul {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: -20px;
}

.MobileNotificationView-Hashtags li {
  list-style: none;
}

.MobileNotificationView-Hashtag {
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  padding: 2px;
  width: 180px;
  height: 20px;
}

.MobileNotificationView-Hashtag img {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
}

.MobileNotificationView-Hashtag button {
  position: relative;
  margin-left: -2px;
  background-color: #f8f8fa;
  border: 1px solid #f8f8fa;
  border-radius: 24px;
  width: 150px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: left;
}

.MobileNotificationView-ReadMore button {
  position: relative;
  left: calc(47.5vw - 50px);
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReadMore img {
  width: 90px;
  height: 20px;
}

.MobileNotificationView-Summary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 20px;
  align-self: start;
}

.MobileNotificationView-NumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileNotificationView-NumUps img {
  width: 20px;
  height: 9px;
}

.MobileNotificationView-NumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-NumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileNotificationView-NumUps-Focus img {
  width: 22px;
  height: 10px;
}

.MobileNotificationView-NumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.MobileNotificationView-NumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileNotificationView-NumDowns img {
  width: 42px;
  height: 9px;
}

.MobileNotificationView-NumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-NumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.MobileNotificationView-NumDowns-Focus img {
  width: 46px;
  height: 10px;
}

.MobileNotificationView-NumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.MobileNotificationView-NumComments button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-NumComments img {
  width: 16px;
  height: 16px;
}

.MobileNotificationView-NumComments p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-NumTokens button {
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-NumTokens p {
  font-family: Pretendard, serif;
  font-size: 12px;
  font-weight: 800;
  color: #767676;
}

.MobileNotificationView-ComingSoon {
  position: relative;
}

.MobileNotificationView-ComingSoon img {
  width: 40px;
  height: 40px;
}

.MobileNotificationView-Comment {
  display: flex;
  flex-direction: column;
  width: 95vw;
  border-top: 1px solid #d4d4d8;
}

.MobileNotificationView-CommentHeader {
  position: relative;
  display: flex;
  width: 99%;
}

.MobileNotificationView-CommentAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileNotificationView-CommentNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
  font-weight: 550;
  color: black;
}

.MobileNotificationView-CommentTime {
  position: relative;
  margin-top: 10px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: gray;
}

.MobileNotificationView-CommentReport button {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 15px;
  right: 10px;
}

.MobileNotificationView-CommentReport img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-CommentBody {
  position: relative;
  width: calc(95vw - 80px);
  font-family: Pretendard, serif;
  font-size: 14px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 56px;
  margin-top: -30px;
}

.MobileNotificationView-CommentSummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 52px;
  align-self: start;
  margin-top: -15px;
}

.MobileNotificationView-CommentNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumUps img {
  width: 20px;
  height: 9px;
}

.MobileNotificationView-CommentNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-CommentNumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumUps-Focus img {
  width: 22px;
  height: 10px;
}

.MobileNotificationView-CommentNumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.MobileNotificationView-CommentNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumDowns img {
  width: 42px;
  height: 9px;
}

.MobileNotificationView-CommentNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-CommentNumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumDowns-Focus img {
  width: 46px;
  height: 10px;
}

.MobileNotificationView-CommentNumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.MobileNotificationView-CommentNumReplies button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumReplies img {
  width: 16px;
  height: 16px;
}

.MobileNotificationView-CommentNumReplies p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-CommentNumTokens button {
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentNumTokens p {
  font-family: Pretendard, serif;
  font-size: 12px;
  font-weight: 800;
  color: #767676;
}

.MobileNotificationView-ReplyInput {
  position: relative;
  width: 95vw;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d4d4d8;
  background-color: #f8f8fa;
  border-bottom: 1px solid #fffefc;
  border-radius: 25px;
}

.MobileNotificationView-ReplyImage img {
  position: relative;
  margin-left: 25px;
  margin-top: 20px;
  height: 20px;
}

.MobileNotificationView-ReplyInputAvatar img {
  position: relative;
  margin-top: 10px;
  margin-left: 2px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 32px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileNotificationView-ReplyInputBody {
  display: flex;
  flex-direction: column;
}

.MobileNotificationView-ReplyInputBody textarea {
  position: relative;
  resize: none;
  width: calc(95vw - 180px);
  height: 40px;
  margin-top: 18px;
  margin-left: 20px;
  font-family: Pretendard, serif;
  font-size: 14px;
  border: 1px solid #f8f8fa;
  border-radius: 10px;
  word-wrap: break-word;
  outline: none;
  background-color: #f8f8fa;
}

.MobileNotificationView-ReplyInputBody button {
  position: absolute;
  right: 10px;
  margin-top: 20px;
  background-color: #229ec5;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #fffefc;
  border: 1px solid #fffefc;
  border-radius: 10px;
  width: 50px;
  height: 30px;
}

.MobileNotificationView-LoadingBar {
  position: relative;
  width: 45vw;
  z-index: 1;
}

.MobileNotificationView-Reply {
  background-color: #f8f8fa;
  border-top: 1px solid #d4d4d8;
}

.MobileNotificationView-ReplyHeader {
  position: relative;
  display: flex;
}

.MobileNotificationView-ReplyAvatar img {
  position: relative;
  margin-top: 20px;
  margin-left: 10px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 36px;
  border-radius: 50%;
  align-self: start;
  border: 3px solid #f1f1f5;
}

.MobileNotificationView-ReplyNickname button {
  position: relative;
  margin-top: 20px;
  margin-left: 2px;
  font-family: Pretendard, serif;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  text-align: left;
}

.MobileNotificationView-ReplyTime {
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 12px;
  color: gray;
}

.MobileNotificationView-ReplyReport button {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  margin-top: 15px;
  right: 20px;
}

.MobileNotificationView-ReplyReport img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-ReplyBody {
  position: relative;
  width: calc(95vw - 120px);
  font-family: Pretendard, serif;
  font-size: 14px;
  text-align: justify;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  word-wrap: break-word;
  margin-left: 103px;
  margin-top: -30px;
}

.MobileNotificationView-ReplySummary {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 100px;
  align-self: start;
  margin-top: -15px;
}

.MobileNotificationView-ReplyNumUps button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReplyNumUps img {
  width: 20px;
  height: 9px;
}

.MobileNotificationView-ReplyNumUps p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-ReplyNumUps-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReplyNumUps-Focus img {
  width: 22px;
  height: 10px;
}

.MobileNotificationView-ReplyNumUps-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #f14b4b;
}

.MobileNotificationView-ReplyNumDowns button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReplyNumDowns img {
  width: 42px;
  height: 9px;
}

.MobileNotificationView-ReplyNumDowns p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #767676;
}

.MobileNotificationView-ReplyNumDowns-Focus button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReplyNumDowns-Focus img {
  width: 46px;
  height: 10px;
}

.MobileNotificationView-ReplyNumDowns-Focus p {
  margin-left: 10px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: #4bacf1;
}

.MobileNotificationView-ReadMoreReplies {
  background-color: #f8f8fa;
  border-bottom: 1px solid #fffefc;
  border-radius: 25px;
}

.MobileNotificationView-ReadMoreReplies button {
  width: 90px;
  height: 4vh;
  background-color: #229ec5;
  color: #fffefc;
  font-family: Pretendard, serif;
  font-size: 16px;
  border: 1px solid #fffefc;
  border-radius: 15px;
  cursor: pointer;
  margin: 10px auto;
}

.MobileNotificationView-Misc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-Misc img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-MiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 50px;
  width: 110px;
  height: 70px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-MiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 40px;
  width: 110px;
  height: 40px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-Block {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.MobileNotificationView-Block img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-Block p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-Report {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 15px;
}

.MobileNotificationView-Report img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-Report p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-Delete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -5px;
}

.MobileNotificationView-Delete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-Delete p {
  position: relative;
  display: flex;
  top: -15px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-CommentMisc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-CommentMisc img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-CommentMiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 50px;
  width: 110px;
  height: 65px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-CommentMiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 16px;
  margin-top: 40px;
  width: 110px;
  height: 35px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-CommentBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -11px;
}

.MobileNotificationView-CommentBlock img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-CommentBlock p {
  position: relative;
  display: flex;
  top: -12px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-CommentReport {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 20px;
}

.MobileNotificationView-CommentReport img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  top: -13px;
}

.MobileNotificationView-CommentReport p {
  position: relative;
  display: flex;
  top: -13px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-CommentDelete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -11px;
}

.MobileNotificationView-CommentDelete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-CommentDelete p {
  position: relative;
  display: flex;
  top: -12px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-ReplyMisc button {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  right: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.MobileNotificationView-ReplyMisc img {
  width: 24px;
  height: 24px;
}

.MobileNotificationView-ReplyMiscFocus {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 50px;
  width: 110px;
  height: 70px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-ReplyMiscFocus-Mine {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 15px;
  margin-top: 50px;
  width: 110px;
  height: 35px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #d4d4d8;
  background-color: #ffffff;
}

.MobileNotificationView-ReplyBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -11px;
}

.MobileNotificationView-ReplyBlock img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-ReplyBlock p {
  position: relative;
  display: flex;
  top: -12px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-ReplyReport {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 20px;
}

.MobileNotificationView-ReplyReport img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-ReplyReport p {
  position: relative;
  display: flex;
  top: -12px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}

.MobileNotificationView-ReplyDelete {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: -11px;
}

.MobileNotificationView-ReplyDelete img {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
}

.MobileNotificationView-ReplyDelete p {
  position: relative;
  display: flex;
  top: -12px;
  left: 10px;
  width: 100px;
  font-family: Pretendard, serif;
  font-size: 14px;
  color: black;
}
